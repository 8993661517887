import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import PubSub from 'pubsub-js';

import Emoji from 'core/components/Icon/Emoji';
import Portal from 'core/components/Portal';

import Close from 'site/icons/Close.svg';

import { REACTIONS_DATA, PUB_SUB_REACTIONS_POPUP } from '../constants';

import styles from './index.styl';

const POPUP_SHOW_TIME = 5000;

export default function Popup({ id, slug }) {
  const emojiSize = slug === 'can_be_better' ? 36 : 28;

  const timerRef = useRef(null);
  const [opened, toggleOpened] = useState(false);

  useEffect(() => {
    const subscription = PubSub.subscribe(PUB_SUB_REACTIONS_POPUP, (msg, reactedId) => {
      toggleOpened(reactedId === id);
    });

    return () => PubSub.unsubscribe(subscription);
  }, [opened, id]);

  useEffect(() => {
    if (opened) {
      timerRef.current = setTimeout(() => toggleOpened(false), POPUP_SHOW_TIME);
    } else {
      clearTimeout(timerRef.current);
    }
    return () => clearTimeout(timerRef.current);
  }, [opened]);

  const onClose = () => {
    toggleOpened(false);
    clearTimeout(timerRef.current);
  };

  if (!slug || !opened) return null;

  return (
    <Portal
      dataQa='popup'
      className={cx(styles.popup, opened && styles._opened, slug && styles['_' + slug])}
    >
      <Emoji
        size={emojiSize}
        type={slug}
        className={styles.emoji}
      />
      <div className={styles.text}>{REACTIONS_DATA[slug].popupText}</div>
      <div className={styles.close}>
        <Close
          width={9}
          height={9}
          onClick={onClose}
        />
      </div>
    </Portal>
  );
}

Popup.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string,
};
