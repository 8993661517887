import PropTypes from 'prop-types';

import styles from './index.styl';


export default function ReadMoreWrapper({ children, parentProps }) {
  const {
    theme,
  } = parentProps;

  const {
    colors: {
      purple60,
    },
    controls: {
      vikontReadMore: {
        block: {
          background,
          borderRadius,
        },
        title: {
          color,
          font,
          textTransform,
          letterSpacing,
          marginBottom,
          textAlign,
        },
      },
    },
  } = theme;

  return (
    <div className={styles.readMore}>
      <style jsx>{`
        .${styles.readMore}
          background ${background}
          border-radius ${borderRadius}px

          &:before
            border-radius ${borderRadius}px
            background ${purple60}

        .${styles.title}
          color ${color}
          font ${font}
          text-transform ${textTransform}
          letter-spacing ${letterSpacing}
          margin-bottom ${marginBottom}
          text-align ${textAlign}
      `}</style>
      <div className={styles.title}>
        Читайте по теме
      </div>
      {children}
    </div>
  );
}

ReadMoreWrapper.propTypes = {
  parentProps: PropTypes.object,
  children: PropTypes.node,
};
