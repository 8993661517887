import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import Alphabet from './Alphabet';
import MobileAlphabet from './MobileAlphabet';


function AlphabetWrapper(props) {
  const {
    isMobile,
    ...otherProps
  } = props;

  const {
    symbols,
  } = otherProps;

  const nothingToShow = !symbols || Object.keys(symbols).length < 1;

  if (nothingToShow) return null;

  if (isMobile) {
    return <MobileAlphabet {...otherProps} />;
  }

  return <Alphabet {...otherProps} />;
}

AlphabetWrapper.propTypes = {
  symbols: PropTypes.shape({
    ru: PropTypes.array,
    en: PropTypes.array,
    other: PropTypes.array,
  }).isRequired,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(AlphabetWrapper);
