import PropTypes from 'prop-types';
import { Fragment } from 'react';

import EmptyWrapper from 'core/components/EmptyWrapper';
import Position from 'core/components/GridPosition';
import Source from 'core/components/TopicFooter/Source';
import { Indent } from 'core/components/Wrappers';
import Divider from 'core/components/Divider';
import Authors from 'core/components/Topic/Authors';

import withTheme from 'core/components/theme';
import withoutSSR from 'core/components/withoutSSR';
import { withBreakpoint, Desktop } from 'core/components/breakpoint';
import H2 from 'core/components/H2';
import H3 from 'core/components/H3';
import withTopic from 'core/components/Topic/withTopic';
import { checkScrollToSiteInTopic } from 'core/components/ScrollToSite';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import NewsDivider from 'site/components/NewsDivider';
import Socializator from 'site/components/Socializator';
import NewsFeed from 'site/components/NewsFeed';
import Recommender from 'site/components/Recommender';
import { MaxWidthWrapper, NegativeMobile } from 'site/components/Wrappers';
import withEmbed from 'site/components/Embed/withEmbed';

import { checkNavigationHeaders, isStarTopic } from 'site/utils';

import TopicsWithStars from './TopicsWithStars';
import TopicTags from './TopicTags';
import SocialMedia from './SocialMedia';
import SimilarStars from './SimilarStars';
import Marquee from './Marquee';

import styles from './index.styl';


const SkippedSSR = withoutSSR(({ children }) => children);

const relationships = resolveRelationships(...[
  ['tags', 'social_network', 'content', ['read_more'], 'imported_topic', 'authors'],
  {},
  { tags: [], social_network: {}, content: {}, read_more: { topic_ids: [] }, imported_topic: {} },
]);

function TopicFooter(props) {
  const {
    isDesktop,
    isMobile,
    topic,
    topicsWithStars,
    similarStars,
    embedMode,
    theme: {
      controls: {
        topicFooter: {
          block: {
            spacing,
            interitemSpacing,
          },
        },
      },
    },
  } = props;

  const { content } = topic;

  if (!content) return null;

  const {
    attributes: {
      source,
      source_url: sourceUrl,
      headline,
      is_has_contents: isHasContents,
      topic_type: topicType,
    },
  } = content;

  const isQuiz = topicType === 'quiz';

  const isScrollToSite = checkScrollToSiteInTopic(topic);

  if (isQuiz) {
    const Positioner = isMobile ? EmptyWrapper : Position;
    const Spacer = isMobile ? EmptyWrapper : Indent;

    return (
      <MaxWidthWrapper>
        <Positioner center>
          <Spacer top={24}>
            <Socializator disableDividers />
          </Spacer>
        </Positioner>
        {!isScrollToSite && (
          <Indent
            top={isMobile ? 40 : 20}
            {...isDesktop && {
              left: -150,
              right: -150,
            }}
          >
            <NewsFeed />
            <Indent top={isMobile ? 30 : 20} />
            <Recommender />
          </Indent>
        )}
      </MaxWidthWrapper>
    );
  }

  const {
    tags,
    content: {
      widgets,
    },
    social_network: socialNetwork,
    imported_topic: {
      link: externalSourceUrl,
    },
    authors,
  } = relationships(content);

  const bottomSpacing = isDesktop ? 60 : 30;
  const isNews = topicType === 'news';
  const withNavigation = isHasContents && checkNavigationHeaders(widgets);

  if (embedMode && isStarTopic(topicType)) {
    const HeadlineWrapper = isMobile ? H3 : H2;
    return (
      <Fragment>
        <HeadlineWrapper is='h2'>{headline}: последние новости на Звук Медиа</HeadlineWrapper>
        <Indent top={20} bottom={20}>
          <TopicsWithStars topics={topicsWithStars} />
        </Indent>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Indent
        top={spacing}
      />
      <MaxWidthWrapper
        {...isStarTopic(topicType) && withNavigation && isDesktop && { className: styles.footerWithNavigation }}
      >
        <SocialMedia socialNetwork={socialNetwork} />

        {!!externalSourceUrl && (
          <SkippedSSR>
            <Indent bottom={interitemSpacing} top={20}>
              <Source title='Ссылка на оригинальный источник: ' sourceUrl={externalSourceUrl}>
                {externalSourceUrl}
              </Source>
            </Indent>
          </SkippedSSR>
        )}

        {isNews && (
          <NegativeMobile>
            <Indent top={isMobile ? 40 : 50} bottom={40}>
              <Marquee />
            </Indent>
          </NegativeMobile>
        )}

        {!!source && (
          <Source sourceUrl={sourceUrl}>
            {source}
          </Source>
        )}

        {tags.length > 0 && (
          <Indent top={20}>
            <TopicTags
              data={tags}
              {...isNews && { linkPostfix: '?type=news' }}
            />
          </Indent>
        )}

        {isStarTopic(topicType) && authors.length > 0 && (
          <Indent top={20}>
            <Authors authors={authors} />
          </Indent>
        )}

        {isNews &&
          <Indent top={20} bottom={30}>
            <NewsDivider />
          </Indent>
        }

        {!isNews && !isStarTopic(topicType) && !isScrollToSite && (
          <Indent left={isDesktop ? -150 : 0} right={isDesktop ? -150 : 0}>
            <Indent
              top={isDesktop ? spacing * 2 : spacing}
              bottom={isDesktop ? spacing : spacing * 1.5}
            >
              {isMobile && (
                <Indent bottom={20}>
                  <NewsDivider />
                </Indent>
              )}
              <NewsFeed />
            </Indent>
            <Recommender />
          </Indent>
        )}
        <Desktop>
          {withNavigation && isStarTopic(topicType) && (
            <Indent top={40} bottom={40}>
              <TopicsWithStars topics={topicsWithStars} />
            </Indent>
          )}
        </Desktop>
      </MaxWidthWrapper>
      {isStarTopic(topicType) && (
        <Fragment>
          {(!withNavigation || isMobile) && (
            <Indent top={isMobile ? 20 : 40} bottom={isMobile ? 20 : 40}>
              <TopicsWithStars topics={topicsWithStars} />
            </Indent>
          )}
          {isDesktop && withNavigation && <Divider />}
          <Indent top={isDesktop ? 40 : 30}>
            <SimilarStars topics={similarStars} />
          </Indent>
        </Fragment>
      )}
      <Indent bottom={isNews ? 25 : bottomSpacing} />
    </Fragment>
  );
}

TopicFooter.propTypes = {
  topic: PropTypes.shape({
    content: modelPropTypes(topicAttributes),
    infinityIndex: PropTypes.number,
  }),
  topicsWithStars: PropTypes.array,
  similarStars: PropTypes.array,
  embedMode: PropTypes.bool,
  isNews: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(withTopic(withEmbed(TopicFooter))));
