import PropTypes from 'prop-types';

import Button from 'core/components/Button';
import Link from 'site/components/Link';

import Metrika from '../../Metrika';

import styles from './index.styl';

export default function Card1({ block, parentProps }) {
  const {
    isMobile,
    topic,
  } = parentProps;

  if (!block || !block.attributes.link) return null;

  const {
    link: widgetLink,
    caption,
  } = block.attributes;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.ears} />
        <div className={styles.button}>
          <div className={styles.prime} />
          <Metrika name='cta_man' topic={topic}>
            <Link
              to={widgetLink}
              type='blank'
              className={styles.buttonLink}
            >
              <Button
                type='ghost'
                size={isMobile ? 'medium' : 'large'}
                background='radial-gradient(100% 100% at 100% 0%, #5ADAFF 0%, #5468FF 100%)'
              >
                <span className={styles.buttonText}>{caption}</span>
              </Button>
            </Link>
          </Metrika>
        </div>
      </div>
    </div>
  );
}

Card1.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
};
