import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Link from 'site/components/Link';
import EmptyWrapper from 'core/components/EmptyWrapper';
import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import modelPropTypes, { opinionAttributes } from 'core/utils/prop-types/model';

import Quotes from 'site/icons/Quotes.svg';

import styles from './index.styl';
import Author from './Author';

function Opinion(props) {
  const {
    theme: {
      colors: {
        divider,
        active1,
        quotes,
      },
      fonts: {
        nuance,
        display,
      },
    },
    block,
    link,
  } = props;

  const {
    attributes: {
      author,
      body,
    },
  } = block;

  const Wrapper = link ? Link : EmptyWrapper;

  return (
    <Fragment>
      <style jsx>{`
        .${styles.opinion}
          border 1px solid ${divider}

          &:after
            font 72px/83px ${nuance}
            color ${active1}

        .${styles.body}
          font 400 23px/28px ${display}
          :global(.mobile) &
            font 400 18px/22px ${display}
            @media (max-width: 375px)
              font 400 14px/17px ${display}
        .${styles.quotes}
          color ${quotes}
      `}</style>
      <div className={styles.quotes}>
        <Quotes />
      </div>
      <div className={styles.opinion}>
        <div className={styles.content}>
          <Wrapper
            to={link} type='opinion'
            className={styles.link}
          >
            <p className={styles.body}>
              {body}
            </p>
            {author && (
              <Author
                author={author}
                additionalClass={styles.authorAnimated}
              />
            )}
          </Wrapper>
        </div>
      </div>
    </Fragment>
  );
}

Opinion.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  parentProps: PropTypes.object,
  /** Модель данных для виджета */
  block: modelPropTypes(opinionAttributes).isRequired,
  /** @ignore */
  theme: PropTypes.object,
  /** Ссылка, по которой происходит переход при клике на контент виджета */
  link: PropTypes.string,
};

export { Opinion as StorybookComponent };

export default withTheme(withBreakpoint(Opinion));
