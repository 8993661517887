import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Divider from 'core/components/Divider';

import { Indent } from 'site/components/Wrappers';


function ListDivider(props) {
  const {
    indent,
    card: Card,
    className,
    ...cardProps
  } = props;

  if (!Card) {
    return (
      <Indent top={indent} bottom={indent}>
        <Divider className={className} />
      </Indent>
    );
  }

  if (!props.position) {
    return <Card {...cardProps} />;
  }

  return (
    <Fragment>
      <Indent top={indent} bottom={indent}>
        <Divider className={className} />
      </Indent>
      <Card {...cardProps} />
    </Fragment>
  );
}

ListDivider.defaultProps = {
  className: 'border: 1px solid #eaeaea',
};

ListDivider.propTypes = {
  /** Отступы сверху и снизу от разделителя */
  indent: PropTypes.number,

  /**
   * Если передана, будет отрендерена под разделителем.
   * Все props, кроме описанных в propTypes, будут переданы карточке.
   */
  card: PropTypes.func,

  /**
   * Для использования в качестве карточки с презентерами типа Feed.
   * Когда презентер передаёт `position: 0`, карточка, переданная в
   * Card, будет отрендерена без разделителя.
   */
  position: PropTypes.number,

  /** Будет передан в Divider из ЛБ */
  className: PropTypes.string,
};

export default ListDivider;
