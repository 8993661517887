export default {
  green: '#21a038',

  // голубой
  azure10: '#272264',
  azure20: '#203883',
  azure30: '#184ea1',
  azure40: '#1164c0',
  azure50: '#0087cd',
  azure60: '#5ab0ff',
  azure70: '#7ac0ff',
  azure80: '#9bd0fe',
  azure90: '#bbe0fe',

  // маджента
  magenta10: '#69004a',
  magenta20: '#94194e',
  magenta30: '#b40057',
  magenta40: '#da005e',
  magenta50: '#ff0066',
  magenta60: '#ff3c8a',
  magenta70: '#fe77ae',
  magenta80: '#feb3d2',
  magenta90: '#fdeef6',

  // фиолетовый
  purple10: '#190a26',
  purple20: '#2a1140',
  purple30: '#3b1859',
  purple40: '#4c1f73',
  purple50: '#632895',
  purple60: '#8253aa',
  purple70: '#a17ec0',
  purple80: '#c1a9d5',
  purple90: '#e0d4ea',

  // синий
  blue10: '#111640',
  blue20: '#171e59',
  blue30: '#1d2773',
  blue40: '#202c80',
  blue50: '#263498',
  blue60: '#515dad',
  blue70: '#7d85c7',
  blue80: '#a8aed6',
  blue90: '#d4d6ea',

  // b/w
  black: '#000',
  gray20: '#202020',
  gray30: '#404040',
  gray40: '#626262',
  gray50: '#808080',
  gray60: '#9f9f9f',
  gray70: '#bfbfbf',
  gray80: '#dfdfdf',
  gray90: '#f9f9f9',
  white: '#fff',

  /**
   * sber herbal
   *
   * Цвета взяты из официального бренд-бука сбер.
   * Есть и другие палитры зеленого: арктический, изумрудный, весенний, но все по 8 цветов.
   */
  herbal10: '#1C4F5F',
  herbal20: '#1D6A64',
  herbal30: '#1F8468',
  herbal40: '#209F6D',
  herbal50: '#21BA72',
  herbal60: '#44D370',
  herbal70: '#6CD89F',
  herbal80: '#C6F4C6',
};
