import Link from 'core/components/Link';

import { YandexMetrikaGoalSender } from 'core/components/MetricsEventSender';

import styles from './index.styl';

export default function Marquee() {
  const text = 'Купи СберПрайм';
  const link = 'https://zvuk.com/sub?utm_source=sz_media';

  return (
    <YandexMetrikaGoalSender
      click='news_line'
      params={{ url: link }}
    >
      <Link
        className={styles.marquee}
        to={link}
        type='secondary'
        title={text}
      >
        <div className={styles.items}>
          {Array.from(Array(5)).map((empty, index) => (
            <span key={index} className={styles.item}>{text}</span>
          ))}
        </div>
        <div className={styles.shadow} />
        <div className={styles.under} />
      </Link>
    </YandexMetrikaGoalSender>
  );
}
