import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { throttle } from 'core/libs/lodash';

import { Block, Section } from 'core/components/Grid';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withTheme from 'core/components/theme';
import { Mobile } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';

import { WIKI_SECTION } from 'site/constants';

import Navigation from './Navigation';


class ContentWithContents extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.navigationRef = React.createRef();
    this.timer = 0;
    this.headlines = [];
    this.currentOffset = 0;
    this.navigationOffset = 0;
    this.containerHeight = 0;
    this.correctedOffset = 0;
    this.navigationHeight = 0;
    this.state = {
      activeId: null,
      activeIndex: 0,
      navigationOffset: 0,
      isTopicContentVisible: false,
    };
  }

  componentDidMount() {
    if (this.props.isDesktop) {
      const {
        theme,
      } = this.props;

      const {
        controls: {
          shapka: {
            height: shapkaHeight,
          },
          wikiContent: {
            section: {
              padding,
            },
          },
        },
      } = theme;

      this.headlines = document.querySelectorAll(`[id^=${WIKI_SECTION}]`);
      this.setActive(this.headlines[0].id, 0);

      this.containerHeight = this.containerRef.current.scrollHeight;
      this.navigationHeight = this.navigationRef.current.scrollHeight;
      this.correctedOffset = shapkaHeight + padding;

      const observer = new IntersectionObserver(this.updateVisibleState, {
        rootMargin: '75% 0%',
      });

      observer.observe(this.containerRef.current);

      if (!!this.headlines.length) {
        this.updateActiveId();
        this.updateNavigationOffset();
        window.addEventListener('scroll', this.onScrollOrResize);
        window.addEventListener('resize', this.onScrollOrResize);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const activeIdChanged = nextState.activeId !== this.state.activeId;
    const navOffsetChanged = nextState.navigationOffset !== this.state.navigationOffset;
    return activeIdChanged || navOffsetChanged || this.state.isTopicContentVisible;
  }

  componentWillUnmount() {
    if (this.props.isDesktop) {
      window.removeEventListener('scroll', this.onScrollOrResize);
      window.removeEventListener('resize', this.onScrollOrResize);
      this.observer?.unobserve(this.containerRef.current);
      if (this.timer !== 0) clearInterval(this.timer);
    }
  }

  updateVisibleState = entries => {
    const firstEntry = entries[0];
    if (firstEntry.isIntersecting && !this.state.isTopicContentVisible) {
      this.setState({
        isTopicContentVisible: true,
      });
    } else if (this.state.isTopicContentVisible) {
      this.setState({
        isTopicContentVisible: false,
      });
    }
  };

  onScrollOrResize = throttle(() => {
    if (this.containerRef) {
      clearInterval(this.timer);
      /**
       * Добавляю задержку на навигацию
       */
      this.timer = setInterval(this.updateNavigationOffset, 500);
    }
    if (this.state.isTopicContentVisible) {
      this.updateActiveId();
    }
  }, 150);

  /**
   * Просчитываем отступ навигации, чтобы она съезжала вниз вместе со скроллом
   */
  updateNavigationOffset = () => {
    // отступ от хедера или предыдущего компонента
    const fixedMargin = 20;
    const containerOffset = this.containerRef.current.getBoundingClientRect().top;
    const shiftedContainer = this.containerHeight - this.navigationHeight - this.correctedOffset;
    const beforeContainer = containerOffset - fixedMargin > 0;
    const afterContainer = -containerOffset + fixedMargin > shiftedContainer;

    const realContainerHeight = Math.round(this.containerRef.current.getBoundingClientRect().height) ||  this.containerHeight;

    if (!(beforeContainer || afterContainer)) {
      this.setState({
        navigationOffset: -containerOffset + this.correctedOffset,
      });
    } else if (afterContainer) {
      this.setState({
        navigationOffset: realContainerHeight -  this.navigationHeight - 6, // 6 is padding active element
      });
    } else if (this.state.navigationOffset !== 0) {
      this.setState({
        navigationOffset: 0,
      });
    }

    clearInterval(this.timer);
  };

  /**
   * Обновляем активный элемент навигации
   */
  updateActiveId = () => {
    const {
      headlines,
    } = this;
    const {
      theme,
    } = this.props;

    const {
      controls: {
        shapka: {
          height: shapkaHeight,
        },
        wikiContent: {
          section: {
            padding,
          },
        },
      },
    } = theme;

    const switchHeight = shapkaHeight + padding;

    let newActiveIndex = null;
    let closestDistance = Infinity;

    headlines.forEach((headline, index) => {
      const headlinePosition = headline.getBoundingClientRect().top;

      const distance = Math.abs(headlinePosition - switchHeight);

      if (headlinePosition <= switchHeight && distance < closestDistance) {
        newActiveIndex = index;
        closestDistance = distance;
      }
    });

    if (newActiveIndex !== null && newActiveIndex !== this.state.activeIndex) {
      this.setActive(headlines[newActiveIndex].id, newActiveIndex);
    }
  };

  setActive = (id, index) => {
    if (index === this.state.activeIndex && id === this.state.activeId) return;

    this.setState({
      activeId: id,
      activeIndex: index,
    });
  };

  render() {
    const {
      content,
      children,
    } = this.props;

    return (
      <div ref={this.containerRef}>
        <Section>
          <Block desktop={3} mobile={12}>
            <Navigation
              activeId={this.state.activeId}
              navigationOffset={this.state.navigationOffset}
              navigationRef={this.navigationRef}
              content={content}
            />
            <Mobile>
              <Indent bottom={30} />
            </Mobile>
          </Block>
          <Block desktop={9} mobile={12}>
            {children}
          </Block>
        </Section>
      </div>
    );
  }
}

ContentWithContents.propTypes = {
  content: PropTypes.array,
  theme: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(withTheme(ContentWithContents));
