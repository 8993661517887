export default [
  { slug: 'full_name', name: 'Полное имя' },
  { slug: 'birth_date', name: 'Дата рождения (возраст)' },
  { slug: 'death_date', name: 'Дата смерти' },
  { slug: 'height', name: 'Рост' },
  { slug: 'music_genre', name: 'Музыкальный жанр' },
  { slug: 'birth_place', name: 'Место рождения' },
  { slug: 'zodiac_ru', name: 'Знак зодиака' },
  { slug: 'education', name: 'Образование' },
  { slug: 'was_in', name: 'Группы', isMarkdown: true },
  { slug: 'origin_place', name: 'Происхождение' },
  { slug: 'active_years', name: 'Активные годы' },
  { slug: 'current_members', name: 'Состав участников', isMarkdown: true },
  { slug: 'past_members', name: 'Бывшие участники', isMarkdown: true },
  { slug: 'discography', name: 'Дискография', isMarkdown: true },
];
