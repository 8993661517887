import ListPage from 'core/components/ListPage';
import H1 from 'core/components/H1';
import Page from 'core/components/Page';

import { filterRequiredParams } from 'core/utils/api';

import RubricTopics from 'site/components/RubricTopics';
import { PageIndent, MaxWidthWrapper } from 'site/components/Wrappers';

import { NEWS_LIMIT } from 'site/constants';

import CardSmall from 'site/cards/CardSmall';

const fields = filterRequiredParams([CardSmall], 'fields');
const include = filterRequiredParams([CardSmall], 'include');


function NewsPage() {
  return (
    <Page title='Последние новости мира музыки' description='Последние новости мира музыки! Каждый день наше СМИ работает, чтобы вы получали свежие новости из мира музыки и факты из жизни любимых артистов.' >
      <PageIndent>
        <MaxWidthWrapper>
          <H1>Новости</H1>
          <ListPage
            limit={NEWS_LIMIT}
            include={include}
            fields={fields}
            topic_type='news'
          >
            {({ content }) =>
              <RubricTopics topics={content} topicType='news' />
            }
          </ListPage>
        </MaxWidthWrapper>
      </PageIndent>
    </Page>
  );
}

export default NewsPage;
