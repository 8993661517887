import PropTypes from 'prop-types';

import { Fragment, memo } from 'react';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import ListPage from 'core/components/ListPage';
import Feed from 'core/components/Feed';
import bindProps from 'core/components/bindProps';
import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';

import { NegativeMobile, Indent } from 'site/components/Wrappers';
import ListDivider from 'site/components/ListDivider';
import { Listing } from 'site/components/Ad/mobile';
import { Parallax } from 'site/components/Ad/desktop';
import NoMaterials from 'site/components/NoMaterials';

import CardVertical from 'site/cards/CardVertical';
import { CardSimple2M } from 'site/cards/CardSimple';

import { STARS_PAGE_LIMIT, STARS_PAGE_LIMIT_MOBILE } from 'site/constants';

const MobileCard = (props) => (
  <Fragment>
    <Indent top={15} bottom={15}>
      <CardSimple2M {...props} />
    </Indent>
    <ListDivider />
  </Fragment>
);

const TopicsList = memo(function(props) {
  const {
    topicsData: {
      content: listContent,
      meta,
    },
    isDesktop,
  } = props;

  if (listContent.length === 0) {
    return (
      <NoMaterials />
    );
  }

  const CardWrapper = bindProps({
    tagsFilterCallback: (el) => el.attributes.tag_type === 'genre',
  })(isDesktop ? CardVertical : MobileCard);

  const limit = isDesktop ? STARS_PAGE_LIMIT : STARS_PAGE_LIMIT_MOBILE;

  return (
    <ListPage
      content={listContent}
      meta={meta}
      limit={limit}
      skipRcm
    >
      {({ content }) => {
        const interitemSpacing = isDesktop ? 20 : 0;
        const firstIndex = isDesktop ? 8 : 5;
        const first = content.slice(0, firstIndex);
        const second = content.slice(firstIndex, limit);
        return (
          <Fragment>
            <Feed
              content={first}
              card={CardWrapper}
              columns={4}
              interitemSpacing={interitemSpacing}
              grid
            />
            {second.length > 0 && (
              <Fragment>
                <AdWrapper bottom={interitemSpacing} top={interitemSpacing}>
                  <Desktop>
                    <Parallax />
                  </Desktop>
                  <Mobile>
                    <NegativeMobile>
                      <Listing />
                    </NegativeMobile>
                  </Mobile>
                </AdWrapper>
                <Feed
                  content={second}
                  card={CardWrapper}
                  columns={4}
                  interitemSpacing={interitemSpacing}
                  grid
                />
              </Fragment>
            )}
          </Fragment>
        );
      }}
    </ListPage>
  );
});

TopicsList.propTypes = {
  topicsData: PropTypes.exact({
    content: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
    meta: PropTypes.object,
  }).isRequired,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(TopicsList);
