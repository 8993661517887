import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withRouter } from 'core/libs/router';

import withTheme from 'core/components/theme';

import Link from 'site/components/Link';
import Bouesque from 'core/components/Bouesque';
import MenuItems from 'core/components/ShapkaMenuItems';

import Close from 'core/components/Icon/Close.svg';
import Burger from 'core/components/Icon/Burger.svg';

import { menuPropTypes } from 'core/utils/prop-types/relationships';
import { getCurrentMenuMatches } from 'core/utils/url-helper';

import NewsFeed from 'site/components/NewsFeed';
import PrimePromo from 'site/components/PrimePromo';
// import SberAuth from 'site/components/SberAuth';

import { CardMainS } from 'site/cards/CardMain';

import { SIDE_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';


export const inputName = 'query';

class ShapkaMobile extends Component {
  constructor(props) {
    super(props);

    this.menuItems = props.menu?.data?.attributes.menu_items || [];
    this.currentMatches = getCurrentMenuMatches(this.menuItems, props.location);

    this.state = {
      isStickyHeaderVisible: false,
      isOpen: false,
    };
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(this.observerCallback);
    this.observer.observe(this.contentEl);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      key: prevLocationKey,
      pathname: prevPathname,
    } = prevProps.location;
    const { location } = this.props;

    const { isOpen } = this.state;
    const { isOpen: wasOpen } = prevState;

    if (isOpen !== wasOpen) {
      document.body.style.overflow = isOpen ? 'hidden' : null;
    }

    if (prevPathname !== location.pathname) {
      this.currentMatches = getCurrentMenuMatches(this.menuItems, location);
    }

    if (isOpen && prevLocationKey !== location.key) {
      this.closeMenu();
    }
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  contentEl = null;

  observerCallback = ([entry]) => {
    this.setState({ isStickyHeaderVisible: !entry.isIntersecting && entry.boundingClientRect.bottom < document.documentElement.clientHeight });
  };

  openMenu = () => {
    this.setState({ isOpen: true });
  };

  closeMenu = () => {
    this.setState({ isOpen: false });
  };


  render() {
    const {
      topTopic,
      news,
      theme: {
        colors,
        animations,
        controls: {
          shapka: shapkaStyles,
        },
      },
      location,
    } = this.props;

    const {
      logo: {
        Icon: Logo,
      },
    } = shapkaStyles;

    const {
      isStickyHeaderVisible,
      isOpen,
    } = this.state;

    const hasNews = !!(news && news.length);
    const hasTopTopic = !!topTopic;

    if (this.menuItems && this.menuItems[0] && this.menuItems[0].link !== '/') {
      this.menuItems.unshift({
        content: 'Главная',
        link: '/',
        show: true,
      });
    }

    const headerMarkup = (
      <Fragment>
        <Link
          to='/'
          className={styles.logoLink}
        >
          <Logo width={shapkaStyles.width} height={shapkaStyles.height} />
        </Link>
        <div className={styles.burgerWrapper}>
          <Burger
            width={24}
            height={24}
            onClick={this.openMenu}
          />
        </div>
      </Fragment>
    );

    const isInnerPageWithTopTopic = !hasNews && hasTopTopic;
    const isInnerPageWithoutTopTopic = !hasNews && !hasTopTopic;

    return (
      <div
        className={cx(
          styles.wrapper,
          hasNews && styles._hasNews,
          hasTopTopic && styles._hasTopTopic,
          isInnerPageWithoutTopTopic && styles._isInnerPageWithoutTopTopic,
          isInnerPageWithTopTopic && styles._isInnerPageWithTopTopic,
        )}
      >
        <style jsx>{`
          .${styles.wrapper}
            color ${colors.content}
            background ${shapkaStyles.background}
          .${styles.header}
            padding ${shapkaStyles.padding}
            height ${shapkaStyles.height}px
          :global(.${styles.logoLink})
            height ${shapkaStyles.logo.height}px
          .card
            padding 0 ${SIDE_INDENT_MOBILE}px
          .${styles.menuWrapper}
            padding 60px ${SIDE_INDENT_MOBILE}px 50px
            background ${colors.content}
            transition ${animations.hover}
          .${styles.content}
            padding-left ${SIDE_INDENT_MOBILE}px
            padding-right ${SIDE_INDENT_MOBILE}px
            color ${colors.primary}
            background ${location.pathname === '/media' ? 'none' : colors.content}
          .${styles.stickyHeader}
            background ${shapkaStyles.background}
            &._visible
              max-height ${shapkaStyles.sticky.height}
            .${styles.header}
              padding ${shapkaStyles.sticky.padding}
              height ${shapkaStyles.sticky.height}
        `}</style>
        <header
          className={styles.header}
          data-qa='zvuk-header'
        >
          {headerMarkup}
        </header>
        {/* <div className={styles.sberPromo}>
          <SberAuth />
        </div> */}
        {!!topTopic && (
          <div className='card'>
            <CardMainS content={topTopic} />
          </div>
        )}
        <div
          className={styles.content}
          ref={el => {this.contentEl = el;}}
        >
          {hasNews && (
            <NewsFeed content={news} />
          )}
        </div>
        <div
          className={cx(styles.stickyHeader, isStickyHeaderVisible && '_visible')}
        >
          <header className={styles.header} data-qa='zvuk-header-sticky'>
            {headerMarkup}
          </header>
        </div>
        <div className={cx(styles.menuWrapper, isOpen && styles._isOpened)}>
          <Close
            width={14}
            height={14}
            className={styles.menuClose}
            onClick={this.closeMenu}
          />
          <div className='clickCatcher' onClick={this.closeMenu}>
            <MenuItems items={this.menuItems} currentItem={this.currentMatches[0]} />
            <PrimePromo />
          </div>
          <div className={styles.menuSearch}>
            <Bouesque />
          </div>
        </div>
      </div>
    );
  }
}

ShapkaMobile.propTypes = {
  topTopic: PropTypes.object,
  news: PropTypes.array,
  menu: menuPropTypes(),
  theme: PropTypes.object,
  location: PropTypes.object,
};

export default withTheme(withRouter(ShapkaMobile));
