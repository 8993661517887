import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import Link from 'site/components/Link';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

function Socials(props, context) {
  const {
    theme: {
      colors: {
        primary,
      },
      socials: socialsAtoms,
    },
  } = props;

  const {
    socials,
  } = context;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .social
          display flex
          align-items center

        .social-item
          display flex
          align-items center
          justify-content center
          width 25px
          height 25px
          border-radius 15px
          border none
          background transparent

          & + &
            margin 0 0 0 40px
            :global(.mobile) &
              margin 0 0 0 20px

          & :global([id*="$"])
            fill ${primary}

        .social-icon
          width 20px
          height 20px
      `}</style>
    </scope>
  );

  return (
    <div className={scoped.wrapClassNames('social')}>
      {socialsAtoms.map(({ name, icon: Icon }) => {
        const {
          link,
        } = socials.find((a) => a.name === name) || {};

        if (!Icon || !link) return null;

        return (
          <Link
            key={name}
            to={link}
            className={scoped.wrapClassNames('social-item')}
            aria-label={name}
          >
            <Icon className={scoped.wrapClassNames('social-icon')} />
          </Link>
        );
      })}
      <scoped.styles />
    </div>
  );
}

Socials.propTypes = {
  theme: PropTypes.object,
};

Socials.contextTypes = {
  socials: PropTypes.array,
};

export default withTheme(Socials);
