import cx from 'classnames';
import PropTypes from 'prop-types';

import ThemeSwitch from 'core/components/ThemeSwitch';

import styles from './index.styl';

export default function Wrapper({ children, isFullscreen, isError }) {
  return (
    <ThemeSwitch name='dark'>
      <div className={cx(styles.container, isFullscreen && styles._fullscreen, isError && styles._error)}>
        {children}
      </div>
    </ThemeSwitch>
  );
}

Wrapper.propTypes = {
  isFullscreen: PropTypes.bool,
  isError: PropTypes.bool,
};
