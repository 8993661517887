import PropTypes from 'prop-types';

import Feed from 'core/components/Feed';
import ListPage from 'core/components/ListPage';
import H1 from 'core/components/H1';

import { withNonPureBreakpoint } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import { DESKTOP, MOBILE } from 'core/components/breakpoint/values';

import { getOffset } from 'core/utils/url-helper';
import resolve from 'core/resolver/resolve';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import modelPropTypes, {
  tagAttributes,
} from 'core/utils/prop-types/model';

import { Indent, MaxWidthWrapper, PageIndent } from 'site/components/Wrappers';

import CardTag from 'site/cards/CardTag';


const limits = {
  [DESKTOP]: 40,
  [MOBILE]: 20,
};

function GenresPage(props) {
  const {
    tags,
    breakpoint,
  } = props;

  return (
    <PageIndent>
      <MaxWidthWrapper>
        <H1>Жанры</H1>
        <Indent top={30} bottom={60} />
        <ListPage
          title='Список всех музыкальных жанров и стилей'
          description='Список музыкальных жанров, стилей и направлений с подробным описанием, отличиями и особенностями звучания.'
          rawData={tags}
          limit={limits[breakpoint]}
        >
          {({ content }) => {
            return (
              <Feed
                content={content}
                interitemSpacing={20}
                columns={2}
                grid
                card={CardTag}
              />
            );
          }}
        </ListPage>
      </MaxWidthWrapper>
    </PageIndent>
  );
}

GenresPage.propTypes = {
  tags: PropTypes.shape({
    data: PropTypes.arrayOf(modelPropTypes(tagAttributes)).isRequired,
    meta: PropTypes.object,
  }).isRequired,
  breakpoint: breakpointPropTypes(),
};


const dataProvider = resolve({
  tags(props) {
    const {
      bebopApi,
      renderError,
      location: { search },
      breakpoint,
    } = props;

    const limit = limits[breakpoint];

    return bebopApi
      .getTags({
        with_filtered_count: 1,
        type: 'genre',
        sort: 'title',
        offset: getOffset(search, limit),
        limit,
      })
      .catch(renderError);
  },
});

export default withNonPureBreakpoint(withPageHocs(dataProvider)(GenresPage));
