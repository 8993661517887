const ZVUK_EMBED_URL = 'https://zvuk.com/embed';

export const getSrcFromMarkup = markup => {
  const resultSrc = RegExp(/src=['|"]([^"']+)['|"]/).exec(markup);

  return resultSrc ? resultSrc[1] : '';
};

export const checkIsMusicEmbed = html => {
  const src = getSrcFromMarkup(html);

  return !!src && src.includes(ZVUK_EMBED_URL);
};
