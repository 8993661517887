export const EMBED_REACTIONS_LS_KEY = 'reactions_embed';
export const STORAGE_MAX_SIZE = 100;
export const PUB_SUB_REACTIONS_POPUP = 'embed.reactionsPopup';

export const REACTIONS_DATA = {
  heart: {
    text: 'Лайк!',
    popupText: 'Вау! Мы будем делать больше такого!',
    goalName: 'embed-like-button',
  },
  clapping_hands: {
    text: 'Респект артисту',
    popupText: 'Артисту - Спасибо! Артистам очень важна ваша поддержка!',
    goalName: 'embed-respect-button',
  },
  can_be_better: {
    text: 'Могло быть лучше',
    popupText: 'Возвращайся, скоро у\u00A0нас появятся плейлисты лучше! И\u00A0громче!',
    goalName: 'embed-be-better-button',
  },
  not_working: {
    text: 'Не работает',
    popupText: 'Упс... Спешим\u00A0исправить!',
    goalName: 'embed-not-working-button',
  },
};
