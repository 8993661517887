import resolve from 'core/resolver/resolve';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';
import { getOffset } from 'core/utils/url-helper';

import queryString from 'core/libs/query-string';
import { compose } from 'core/libs/recompose';

import { RUBRICS_PAGE_LIMIT, NEWS_RUBRIC_LIMIT } from 'site/constants';

import CardVertical from 'site/cards/CardVertical';
import CardStory from 'site/cards/CardStory';
import CardMainHero from 'site/cards/CardMainHero';

import {
  listPageTopicsFetcher,
  listPageSymbolsFetcher,
  listPageGeoFetcher,
  listPageGenreFetcher,
} from 'site/fetchers/listPage';


export const queryTypeMap = {
  articles: {
    queryType: 'articles',
    requestType: '-news,artist_group,artist_person',
    withSearch: false,
  },
  stars: {
    queryType: 'stars',
    requestType: 'artist_group,artist_person',
    withSearch: true,
  },
  default: {
    queryType: 'news',
    requestType: 'news',
    withSearch: false,
  },
};

const getFields = match => {
  const {
    params: {
      level_1: level1,
      level_2: level2,
      tagType,
    },
  } = match;

  const isTag = !!tagType;

  const entityType = isTag ? 'tag' : 'rubric';
  const entitySlug = isTag ? level2 : level1;

  return { entitySlug, entityType };
};

const dataProvider =  resolve({
  tag(props) {
    const {
      bebopApi,
      renderError,
      match: { params: { level_2: level2, level_3: level3 } },
    } = props;

    return bebopApi
      .getTag({
        tag_slug: level3 || level2,
        tag_root: level3 ? level2 : null,
      })
      .then(denormalizeData)
      .catch(renderError);
  },
});

const additionalProvider = resolve({
  async filtersData(props) {
    const {
      location: {
        search,
      },
      tag,
    } = props;

    if (!tag) return null;

    const params = queryString.parse(search);
    const { type } = params;

    const { withSearch } = queryTypeMap[type] || queryTypeMap.default;
    const tagType = tag?.attributes?.tag_type;

    if ((tagType !== 'genre' && tagType !== 'geo') || !withSearch) return null;

    return await Promise.allSettled([
      listPageSymbolsFetcher()(props),
      listPageGeoFetcher()(props),
      listPageGenreFetcher()(props),
    ])
      .then(([symbols, geo, genre]) => ({
        symbols: symbols.value,
        geo: geo.value,
        genre: genre.value,
      }));
  },

  topicsData(props) {
    const {
      bebopApi,
      renderError,
      location: {
        search,
      },
      match: { params: { level_2: level2, level_3: level3 } },
      tag,
    } = props;

    if (!tag) return null;

    const params = queryString.parse(search);
    const { type } = params;

    const { withSearch } = queryTypeMap[type] || queryTypeMap.default;
    const tagType = tag?.attributes?.tag_type;

    if ((tagType === 'genre' || tagType === 'geo') && withSearch) {
      return listPageTopicsFetcher({ disabledFilters: [tagType] })(props);
    }

    const {
      requestType,
    } = queryTypeMap[type] || queryTypeMap.default;

    const limit = requestType === 'news' ? NEWS_RUBRIC_LIMIT : RUBRICS_PAGE_LIMIT;

    return bebopApi.
      getTopics({
        tag: level3 || level2,
        tag_root: level3 ? level2 : null,
        topic_type: requestType,
        limit,
        include: filterRequiredParams([CardVertical, CardMainHero, CardStory], 'include'),
        fields: filterRequiredParams([CardVertical, CardMainHero, CardStory], 'fields'),
        with_filtered_count: 1,
        offset: getOffset(search, limit),
      })
      .catch(renderError);
  },

  typeSwitcherNews(props) {
    const {
      bebopApi,
      match,
    } = props;

    const request = getFields(match);

    return bebopApi.
      getTopics({
        [request.entityType]: request.entitySlug,
        topic_type: 'news',
        limit: 1,
        fields: null,
      })
      .then(denormalizeData)
      .catch(() => null);
  },

  typeSwitcherStars(props) {
    const {
      bebopApi,
      match,
    } = props;

    const request = getFields(match);

    return bebopApi.
      getTopics({
        [request.entityType]: request.entitySlug,
        topic_type: 'artist_group,artist_person',
        limit: 1,
        fields: null,
      })
      .then(denormalizeData)
      .catch(() => null);
  },

  typeSwitcherArticles(props) {
    const {
      bebopApi,
      match,
    } = props;

    const request = getFields(match);

    return bebopApi.
      getTopics({
        [request.entityType]: request.entitySlug,
        topic_type: '-news,artist_group,artist_person',
        limit: 1,
        fields: null,
      })
      .then(denormalizeData)
      .catch(() => null);
  },
});

export const composedDataProvider = compose(dataProvider, additionalProvider);
