import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { withRouter } from 'core/libs/router';
import PubSub from 'pubsub-js';
import cx from 'classnames';

import { callYaMetrikaMethod } from 'core/utils/counters-helper';

import { withBreakpoint } from 'core/components/breakpoint';

import Emoji from 'core/components/Icon/Emoji';
import Scroller from 'core/components/Scroller';

import { REACTIONS_DATA, PUB_SUB_REACTIONS_POPUP } from '../constants';
import { addReactionToLS } from '../utils';

import styles from './index.styl';


const Wrapper = withBreakpoint(({ isDesktop, children }) => {
  if (isDesktop) return <div className={styles.reactions}>{children}</div>;
  return <Scroller listClassName={styles.reactions}>{children}</Scroller>;
});

function Controls(props, { counterIds }) {
  const {
    id,
    handleReacted,
    reactedReactionSlug,
    location,
    embedUrl,
  } = props;

  const sendGoals = useCallback(slug => {
    const date = new Date();
    callYaMetrikaMethod(
      counterIds.yandexMetrika,
      'reachGoal',
      REACTIONS_DATA[slug].goalName,
      {
        page_url: location.pathname,
        embed_url: embedUrl,
        reaction: slug,
        date: date.toISOString(),
      },
    );
  }, [location.pathname, embedUrl, counterIds.yandexMetrika]);

  const onChange = useCallback(event => {
    const { value: slug } = event.target;

    addReactionToLS(id, slug);
    PubSub.publish(PUB_SUB_REACTIONS_POPUP, id);
    handleReacted(slug);
    sendGoals(slug);
  }, [id, handleReacted, sendGoals]);

  return (
    <Wrapper>
      {Object.keys(REACTIONS_DATA).map(slug => {
        const { text } = REACTIONS_DATA[slug];
        const checked = reactedReactionSlug === slug;

        return (
          <label key={slug}>
            <input
              checked={checked}
              value={slug}
              className={cx(styles.input, checked && '_checked')}
              name={'reactions_emoji_' + id}
              onChange={onChange}
              type='radio'
              disabled={!!reactedReactionSlug}
            />
            <div className={styles.content}>
              <Emoji size={22} type={slug} />
              <div className={styles.text}>{text}</div>
            </div>
          </label>
        );
      })}
    </Wrapper>
  );
}

Controls.propTypes = {
  id: PropTypes.string.isRequired,
  reactedReactionSlug: PropTypes.string,
  handleReacted: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  embedUrl: PropTypes.string,
};

Controls.contextTypes = {
  counterIds: PropTypes.object,
};

export default withRouter(Controls);
