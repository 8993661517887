import PropTypes from 'prop-types';

import { compose } from 'core/libs/recompose';

import loader from 'core/resolver/loader';
import resolve from 'core/resolver/resolve';

import resolveRelationships from 'core/utils/relationships';
import { denormalizeData } from 'core/utils/api';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Drum from 'core/components/Drum';

import { Indent, NegativeMobile } from 'site/components/Wrappers';
import CardSeries from 'site/cards/CardSeries';

import Skeleton from './Skeleton';

import { SIDE_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';


const relationships = resolveRelationships(['read_more'], {});

function ReadMoreSeries({ topic, content, parentProps }) {
  const {
    isMobile,
    theme,
  } = parentProps;

  if (!content) return null;

  const {
    list_headline: listHeadline,
  } = topic.attributes || {};

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .container
          padding 0 ${isMobile ? SIDE_INDENT_MOBILE + 'px' : ''}
      `}</style>
    </scope>
  );

  return (
    <div className={styles.readMore}>
      <style jsx>{`
        .${styles.title}
          font 400 17px/21px ${theme.fonts.display}
      `}</style>
      <div className={styles.title}>
        {listHeadline}
      </div>
      <Indent top={isMobile ? 20 : 27}>
        <NegativeMobile>
          <Drum
            containerClass={scope.wrapClassNames('container')}
            spaceBetween={isMobile ? 10 : 20}
            arrowsShift={25}
            itemWidth={255}
            itemWidthMobile={230}
            content={content}
            card={CardSeries}
            fadePrev='transparent'
            fadeNext='transparent'
            dataQa='series'
            grid
          />
        </NegativeMobile>
      </Indent>
      <scope.styles />
    </div>
  );
}

ReadMoreSeries.propTypes = {
  content: PropTypes.array,
  parentProps: PropTypes.object,
  topic: PropTypes.object,
};

const dataProvider = resolve({
  content: ({ topic, parentProps, bebopApi }) => {
    return bebopApi.getTopics({
      limit: 1,
      topic_type: 'series',
      include: 'read_more',
      ids: topic.id,
    })
      .then(denormalizeData)
      .then(denormalizedSeries => {
        const series = denormalizedSeries[0];
        const topicIds = relationships(series).read_more.topic_ids;
        return bebopApi
          .getTopics({
            ids: topicIds.filter(id => id !== parentProps.topic.content.id),
            include: CardSeries.requiredPayloadImports,
            fields: CardSeries.requiredPayloadFields,
          })
          .then(denormalizeData);
      })
      .catch(() => []);
  },
});

export default compose(
  loader(Skeleton),
  dataProvider
)(ReadMoreSeries);
