import PropTypes from 'prop-types';

import { Indent } from 'core/components/Wrappers';

import AlphabetWrapper from '../AlphabetWrapper';
import Selectors from '../Selectors';

function FiltersWrapper(props) {
  const {
    toggleFilter,
    symbols,
    geo,
    genre,
    queryParams,
    ...otherProps
  } = props;
  const { first_char: char } = queryParams;

  return (
    <Indent top={20} bottom={30}>
      <AlphabetWrapper
        {...char && { char: decodeURIComponent(char) }}
        symbols={symbols}
        toggleFilter={toggleFilter}
      />
      <Selectors
        geo={geo}
        genre={genre}
        toggleFilter={toggleFilter}
        filterValues={{ ...queryParams }}
        {...otherProps}
      />
    </Indent>
  );
}

FiltersWrapper.propTypes = {
  toggleFilter: PropTypes.func,
  symbols: PropTypes.object,
  genre: PropTypes.array,
  geo: PropTypes.array,
  queryParams: PropTypes.object,
};

export default FiltersWrapper;
