import Position from 'core/components/GridPosition';
import H2 from 'core/components/H2';

import { Indent } from 'core/components/Wrappers';


function NoMaterials() {
  return (
    <Position center>
      <Indent top={100} bottom={100}>
        <H2>
          Материалов не найдено
        </H2>
      </Indent>
    </Position>
  );
}

export default NoMaterials;
