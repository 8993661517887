import PropTypes from 'prop-types';
import { compose } from 'core/libs/recompose';

import Link from 'site/components/Link';
import withTheme from 'core/components/theme';
import withMenu from 'core/components/withMenu';
import { withBreakpoint } from 'core/components/breakpoint';
import FullWidth from 'core/components/FullWidth';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { menuPropTypes } from 'core/utils/prop-types/relationships';

import Socials from 'site/components/Socials';

import GooglePlay from 'site/icons/GooglePlay.svg';
import Logo from 'site/icons/LogoWhite';

const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.zvooq.openplay&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';


function Footer({ theme, menu, isMobile, isDesktop }) {
  const {
    colors: {
      primary,
      magenta50,
      azure60,
    },
    fonts: {
      nuance: fontNuance,
    },
  } = theme;
  const menuItems = menu ? menu.data.attributes.menu_items : [];

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .footer
          background 50% url(/images/waves/waves.webp) no-repeat, linear-gradient(180deg,#2e337d 0%,#000 60%)
          background-size auto 450px

          :global(.mobile) &
            background no-repeat url("/images/waves/small-waves.webp") 0 0, linear-gradient(180deg, #2E337D 0%, #000000 100%)
            background-size contain
            flex-direction column
            text-align center
            padding 20px

        .content
          margin 0 auto
          box-sizing border-box

        .innerWrapper
          display flex
          flex-direction row
          justify-content space-between
          align-items center
          margin-bottom 30px

          :global(.mobile) &
            flex-direction column

        .menu
          display flex
          flex-direction row
          justify-content space-between
          list-style none
          padding 0
          margin-bottom 30px

          :global(.mobile) &
            flex-wrap wrap
            margin 20px 0 10px 0

        .menuItem
          :global(.mobile) &
            width 50%
            margin-bottom 20px

        .link
          &
          &:hover
          &:active
            text-decoration none

        .project
          display flex
          :global(.mobile) &
            flex-direction: column-reverse

        .urlAndYear
          opacity .6

        .stores
          width 320px
          display flex
          flex-direction row
          justify-content space-between

          :global(.mobile) &
            width 100%
            justify-content center
            margin-bottom 30px
            .linkToStore
              & :global(svg)
                width 90%

        .mistakeWarning
          margin 0

        .copyright
          opacity .6
          margin-top 20px
      `}</style>
      <style jsx>{`
        .content
          padding 30px 40px
          max-width ${theme.layout.maxWidth}
        .footer
          font 400 14px/18px ${fontNuance}
          color ${primary}

        .link
          font 400 16px/20px ${fontNuance}
          color ${primary}
          &:visited
          &:active
            color ${primary}
          &:hover
            color ${azure60}

        .linkSmall
          font 400 14px/18px ${fontNuance}
          :global(.desktop) &
            margin-left 60px
          :global(.mobile) &
            display inline-block
            margin-bottom 10px

        .linkLegal
          font 400 14px/18px ${fontNuance}
          :global(.desktop) &
            margin-left 40px
          :global(.mobile) &
            display inline-block
            margin-bottom 10px


        .copyright
          font 400 11px/14px ${fontNuance}

        .hotkeys
          color ${magenta50}
      `}</style>
    </scope>
  );

  const Wrapper = isDesktop ? FullWidth : 'div';
  const wrapperProps = isDesktop && {
    ignoreLayoutIndents: true,
  };

  return (
    <Wrapper
      className={scoped.wrapClassNames('footer')}
      {...wrapperProps}
    >
      <div className={scoped.wrapClassNames('content')}>
        {isMobile && <Logo />}
        <ul className={scoped.wrapClassNames('menu')}>
          {menuItems.map((item, i) => (
            <li key={i} className={scoped.wrapClassNames('menuItem')}>
              <Link
                className={scoped.wrapClassNames('link')}
                to={item.link}
              >
                {item.content}
              </Link>
            </li>
          ))}
        </ul>
        <div className={scoped.wrapClassNames('innerWrapper')}>
          <div className={scoped.wrapClassNames('stores')}>
            <Link className={scoped.wrapClassNames('linkToStore')} to={GOOGLE_PLAY_URL}>
              <GooglePlay />
            </Link>
          </div>
          <Socials />
        </div>
        <div className={scoped.wrapClassNames('innerWrapper')}>
          <div className={scoped.wrapClassNames('project')}>
            <span className={scoped.wrapClassNames('urlAndYear')}>Zvuk.com, {new Date().getFullYear()} г.</span>
            <span>
              <Link
                className={scoped.wrapClassNames('link', 'linkSmall')}
                to='/about'
              >
                О проекте
              </Link>
            </span>
            <span>
              <Link
                className={scoped.wrapClassNames('link', 'linkLegal')}
                to='/legal'
              >
                Правовая информация
              </Link>
            </span>
            <span>
              <Link
                className={scoped.wrapClassNames('link', 'linkLegal')}
                to='/legal/terms-of-use'
              >
                Условия использования
              </Link>
            </span>
            <span>
              <Link
                className={scoped.wrapClassNames('link', 'linkLegal')}
                to='/legal/privacy'
              >
                Политика конфиденциальности
              </Link>
            </span>
          </div>
        </div>
        {!isMobile && (
          <div className={scoped.wrapClassNames('innerWrapper')}>
            <p className={scoped.wrapClassNames('mistakeWarning')}>
              Нашли опечатку? Выделите текст и нажмите <span className={scoped.wrapClassNames('hotkeys')}>Ctrl+Enter</span>
            </p>
          </div>
        )}
        <p className={scoped.wrapClassNames('copyright')}>
          Все права защищены. Полное или частичное копирование материалов Сайта в коммерческих целях разрешено только с письменного разрешения владельца Сайта. В случае обнаружения нарушений, виновные лица могут быть привлечены к ответственности в соответствии с действующим законодательством Российской Федерации.
        </p>
      </div>
      <scoped.styles />
    </Wrapper>
  );
}

Footer.propTypes = {
  theme: PropTypes.object,
  menu: menuPropTypes(),
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

export default compose(
  withMenu('footer'),
  withTheme,
  withBreakpoint,
)(Footer);
