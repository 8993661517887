import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import Feed from 'core/components/Feed';

import CardVertical from 'site/cards/CardVertical';


function TopicsWithStars({ topics }) {
  if (!topics || !topics.length) return null;

  return (
    <Feed
      content={topics}
      card={CardVertical}
      interitemSpacing={10}
      columns={3}
      grid
    />
  );
}

TopicsWithStars.propTypes = {
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
};

export default TopicsWithStars;
