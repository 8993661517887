import { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Button from 'core/components/Button';
import withTheme from 'core/components/theme';

import { Indent } from 'core/components/Wrappers';

import { symbolsMap, ACTIVE_LANGUAGE_BUTTON_ID } from 'site/constants';

import styles from './index.styl';

const scope = resolveScopedStyles(
  <scope>
    <style jsx>{`
      .${styles.charButtonWrapper}
        height 29px
        width 29px
    `}</style>
  </scope>
);

class Alphabet extends Component {
  constructor(props) {
    super(props);
    this.timer = 0;

    const {
      char,
      symbols,
    } = props;

    const symbolKeys = Object.keys(symbols);

    this.state = {
      activeLanguage: symbolKeys.find(key => symbols[key].includes(char)) || symbolKeys[0],
      topOffset: 0,
      isLanguageOpened: false,
    };
  }

  componentDidMount() {
    const {
      char,
    } = this.props;
    document.addEventListener('mousedown', this.handleClickOutside);
    if (char) {
      this.setState({
        topOffset: this.getWrapperTop(),
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    if (this.timer !== 0) clearInterval(this.timer);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      clearInterval(this.timer);
      this.toggleLanguageState();
    }
  };

  getWrapperTop = () => {
    const target = document.getElementById(ACTIVE_LANGUAGE_BUTTON_ID);
    if (!target) return 0;
    return target.offsetTop || 0;
  };

  toggleLetter = (el) => {
    this.props.toggleFilter('first_char', el);
  };

  toggleLanguage = (el) => {
    if (this.state.activeLanguage === el) return;
    this.setState({
      activeLanguage: el,
    });
    this.props.toggleFilter('first_char', 'all');
  };

  toggleLanguageState = () => {
    this.setState({
      topOffset: this.getWrapperTop(),
      isLanguageOpened: false,
    });
  };

  toggleOpened = () => {
    clearInterval(this.timer);
    this.setState({ isLanguageOpened: true });
    this.timer = setInterval(this.toggleLanguageState, 5000);
  };

  render() {
    const {
      symbols,
      char,
      theme,
    } = this.props;

    const {
      colors: {
        gray40,
        blue60,
        divider,
      },
      fonts: {
        text,
      },
    } = theme;

    const {
      activeLanguage,
      topOffset,
      isLanguageOpened,
    } = this.state;

    return (
      <Indent bottom={20}>
        <div className={styles.alphabetWrapper}>
          <style jsx>{`
            .charContent
              font 14px/17px ${text}
              font-weight 400
              text-decoration underline
              &._isActiveChar
                font-weight 700
                text-decoration none
            .languageContent
              color ${gray40}
              &._isActiveLanguage
                color ${blue60}
            .${styles.switchWrapper}
              background-color ${divider}
            .${styles.switchInnerWrapper}
              transform translateY(${-topOffset}px)
              .${styles._isOpened} &
                transform translateY(0px)
          `}</style>
          {symbols[activeLanguage].map((el, i) => {
            const isActiveChar = el === char;
            return (
              <Button
                key={i}
                className={scope.wrapClassNames(styles.charButtonWrapper)}
                type='wiki3'
                state={isActiveChar ? 'active' : 'idle'}
                disabled={isActiveChar}
                height='29px'
                minWidth='29px'
                onClick={() => this.toggleLetter(el)}
              >
                <div className={cx('charContent', isActiveChar && '_isActiveChar')}>
                  {el}
                </div>
              </Button>
            );
          })}
          <div
            className={cx(styles.switchWrapper, isLanguageOpened && styles._isOpened)}
            ref={this.setWrapperRef}
            onClick={this.toggleOpened}
          >
            <div className={styles.switchInnerWrapper}>
              {Object.keys(symbols)
                .map((key, i) => {
                  const isActiveLanguage = key === activeLanguage;
                  return (
                    <Button
                      key={i}
                      className={scope.wrapClassNames(styles.switchButtonWrapper)}
                      type='ghost'
                      state={isActiveLanguage ? 'active' : 'idle'}
                      minWidth='40px'
                      onClick={() => this.toggleLanguage(key)}
                      {...isActiveLanguage && { id: ACTIVE_LANGUAGE_BUTTON_ID }}
                    >
                      <div className={cx('languageContent', isActiveLanguage && '_isActiveLanguage')}>
                        {symbolsMap[key].label}
                      </div>
                    </Button>
                  );
                })
              }
            </div>
          </div>
          <scope.styles />
        </div>
      </Indent>
    );
  }
}

Alphabet.propTypes = {
  symbols: PropTypes.shape({
    ru: PropTypes.array,
    en: PropTypes.array,
    other: PropTypes.array,
  }).isRequired,
  char: PropTypes.string,
  toggleFilter: PropTypes.func,
  theme: PropTypes.object,
};

export default withTheme(Alphabet);
