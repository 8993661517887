import brandColors from './brandColors';


const colors = {
  ...brandColors,
  primary: '#fff', // Основной цвет, текст
  layout: '#000', // цвет фотна body
  content: '#000', // цвет фона контентной области
  divider: '#eaeaea', // рамки, разделители
  error: '#fe3a3a', // ошибки
  get active1() {
    return this.blue60;
  },
  get active2() {
    return this.active1;
  },
  get caption() {
    return this.primary;
  },
  get error2() { // Ошибка в галерее + модальном окне
    return this.primary;
  },
  get credits() { // Подписи к галерее в полноэкранном
    return this.gray40;
  },
  get hint() { // Подписи к тексту, рубрики, доп. информация
    return this.gray40;
  },
  get accent() {
    return this.active1;
  },
  get placeholder() {
    return this.gray70;
  },
  get theme() { // цвет темы, meta theme-color, tile-color, safari-pinned-tab
    return this.active1;
  },
};

export default {
  colors,
};
