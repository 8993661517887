import { denormalizeData } from 'core/utils/api';
import { getOffset } from 'core/utils/url-helper';

import queryString from 'core/libs/query-string';

import { STARS_PAGE_LIMIT, STARS_PAGE_LIMIT_MOBILE } from 'site/constants';


const tagsProvider = (bebopApi, tagType) => {
  const requestParams = {
    with_filtered_count: 1,
    sort: 'title',
    type: tagType,
  };

  return bebopApi
    .getTags({
      ...requestParams,
    })
    .then(firstPart => {
      const limitPerRequest = firstPart.data.length;
      const filteredCount = firstPart.meta.filtered_count;

      // Если все теги поместились в первом ответе, возвращаем массив,
      // состоящий только из первой части тегов.
      if (filteredCount <= limitPerRequest) return [firstPart];

      // Если тегов больше, чем пришло в ответе на первый запрос,
      // формируем список отступов для последующих запросов...
      const offsets = [];

      for (let i = limitPerRequest; i < filteredCount; i += limitPerRequest) {
        offsets.push(i);
      }

      // ... и отправляем эти запросы, дополняя их уже полученными тегами.
      return Promise.all([
        firstPart,
        ...offsets.map(offset => bebopApi
          .getTags({
            ...requestParams,
            offset,
          })
        ),
      ]);
    })
    .then(rawData => rawData.reduce((result, iteration) => ([
      ...result,
      ...denormalizeData(iteration),
    ]), []))
    .catch(e => {
      console.error(e);
      return [];
    });
};

export const listPageTopicsFetcher = (customParams = {}) => (componentProps) => {
  const props = { ...componentProps, ...customParams };

  const {
    bebopApi,
    location: { search },
    disabledFilters = [],
    isDesktop,
    renderError,
    match,
  } = props;

  const isTagPage = match.params.tagType === 'label';
  const queryFilters = queryString.parse(search);
  const {
    type, // eslint-disable-line
    page, // eslint-disable-line
    query,
    first_char: char,
    ...otherFilters
  } = queryFilters;

  const limit = isDesktop ? STARS_PAGE_LIMIT : STARS_PAGE_LIMIT_MOBILE;

  const requestParams = {
    limit,
    topic_type: 'artist_group,artist_person',
    offset: getOffset(search, limit),
    include: 'image,tags',
  };
  let promise;

  if (!!query) {
    promise = bebopApi.getSearch({
      ...requestParams,
      query: decodeURIComponent(query),
      title_only: 1,
    });
  } else {
    const otherTags = Object.keys(otherFilters).reduce((result, key) => {
      const value = otherFilters[key];
      if (disabledFilters.includes(key) || !value) return result;
      return result + value + ',';
    }, '');
    const tag = isTagPage
      ? `${otherTags}${match.params.level_2}`
      : otherTags;

    promise = bebopApi.getTopics({
      ...requestParams,
      fields: 'link,headline,list_headline',
      with_filtered_count: 1,
      all_tags: tag,
      sort: 'list_headline,headline',
      ...char && { first_char: decodeURIComponent(char) },
    });
  }

  return promise
    .then(data => ({
      content: denormalizeData(data),
      meta: {
        filtered_count: data.meta.filtered_count || data.meta.search_results?.total_found || 0,
      },
    }))
    .catch(renderError);
};

export const listPageSymbolsFetcher = (customParams = {}) => (componentProps) => {
  const props = { ...componentProps, ...customParams };

  const { bebopApi } = props;

  return bebopApi
    .getAlphabet({
      topic_type: 'artist_group,artist_person',
    })
    .then(symbolsData => {
      Object.keys(symbolsData).forEach(groupKey => {
        if (symbolsData[groupKey].length < 1) delete symbolsData[groupKey];
      });
      return symbolsData;
    })
    .catch(e => {
      console.error(e);
      return [];
    });
};

export const listPageGenreFetcher = (customParams = {}) => (componentProps) => {
  const props = { ...componentProps, ...customParams };

  const { bebopApi } = props;

  return tagsProvider(bebopApi, 'genre');
};

export const listPageGeoFetcher = (customParams = {}) => (componentProps) => {
  const props = { ...componentProps, ...customParams };

  const { bebopApi } = props;

  return tagsProvider(bebopApi, 'geo');
};
