export default {
  0: {
    imagePosition: 'left',
    imageWidth: 80,
    imageRatio: 1,
  },
  1: {
    imagePosition: 'top',
    imageWidth: 80,
    imageRatio: 1,
  },
  2: {
    imagePosition: 'right',
    imageWidth: 75,
    imageRatio: 1.5,
  },
};
