import { Fragment } from 'react';
import PropTypes from 'prop-types';

import queryString from 'core/libs/query-string';

import withPageHocs from 'core/components/withPageHocs';
import { withBreakpoint } from 'core/components/breakpoint';

import TypeSwitcher from 'site/components/TypeSwitcher';
import ListPageWithFilters from 'site/components/ListPageWithFilters';

import PageWrapper from './PageWrapper';
import TopicsList from './TopicsList';
import { composedDataProvider, queryTypeMap } from './composedDataProvider';


function TagPage(props) {
  const {
    tag,
    filtersData,
    topicsData,
    typeSwitcherNews,
    typeSwitcherStars,
    typeSwitcherArticles,
    location: {
      search,
    },
  } = props;

  const params = queryString.parse(search);
  const { type } = params;

  const {
    queryType: topicType,
  } = queryTypeMap[type] || queryTypeMap.default;

  return (
    <PageWrapper tag={tag} topicType={topicType}>
      {({ tagType }) => (
        <Fragment>
          <TypeSwitcher
            news={typeSwitcherNews}
            stars={typeSwitcherStars}
            articles={typeSwitcherArticles}
            topicType={topicType}
            tagType={tagType}
          />
          {filtersData ? (
            <ListPageWithFilters
              topicsData={topicsData}
              disabledFilters={[tagType]}
              {...filtersData}
              allowNoindex
            />
          ) : (
            <TopicsList type={type} topicsData={topicsData} />
          )}
        </Fragment>
      )}
    </PageWrapper>
  );
}

TagPage.propTypes = {
  tag: PropTypes.object,
  filtersData: PropTypes.object,
  topicsData: PropTypes.object,
  typeSwitcherNews: PropTypes.array,
  typeSwitcherStars: PropTypes.array,
  typeSwitcherArticles: PropTypes.array,
  location: PropTypes.object,
};

export default withBreakpoint(withPageHocs(composedDataProvider)(TagPage));
