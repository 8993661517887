import { Fragment } from 'react';
import PropTypes from 'prop-types';

import ListPage from 'core/components/ListPage';
import EmptyWrapper from 'core/components/EmptyWrapper';

import { denormalizeData } from 'core/utils/api';
import modelPropTypes, {
  topicAttributes,
} from 'core/utils/prop-types/model';

import RubricTopics from 'site/components/RubricTopics';
import NoMaterials from 'site/components/NoMaterials';
import Noindex from 'site/components/Noindex';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import { queryTypeMap } from '../composedDataProvider';


function TopicsList(props) {
  const {
    topicsData: {
      meta,
      ...rawData
    },
    type,
  } = props;

  const topics = denormalizeData(rawData);

  const noindexElement = (
    <Noindex
      topicsLength={meta.filtered_count}
    />
  );

  if (!topics || !topics.length) {
    return (
      <Fragment>
        {noindexElement}
        <NoMaterials />
      </Fragment>
    );
  }

  const {
    queryType: topicType,
  } = queryTypeMap[type] || queryTypeMap.default;

  return (
    <ListPage
      content={topics}
      meta={meta}
      limit={RUBRICS_PAGE_LIMIT}
      skipRcm
    >
      {({ content }) => (
        <EmptyWrapper>
          {noindexElement}
          <RubricTopics topics={content} topicType={topicType} />
        </EmptyWrapper>
      )}
    </ListPage>
  );
}

TopicsList.propTypes = {
  topicsData: PropTypes.shape({
    data: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
    meta: PropTypes.shape({
      filtered_count: PropTypes.number,
    }),
  }),
  type: PropTypes.string,
};

export default TopicsList;
