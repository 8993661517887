import PropTypes from 'prop-types';

import ButtonWithIcon from 'core/components/ButtonWithIcon';
import Link from 'site/components/Link';

import Metrika from '../../Metrika';

import ArrowRightRounded from 'site/icons/ArrowRightRounded';

import styles from './index.styl';

export default function Card4({ block, parentProps }) {
  const {
    isMobile,
    topic,
    theme,
  } = parentProps;

  if (!block || !block.attributes.link) return null;

  const {
    link: widgetLink,
    caption,
  } = block.attributes;

  return (
    <div className={styles.container}>
      <style jsx>{`
        .${styles.headline}
          font-family ${theme.fonts.display}
      `}</style>
      <div className={styles.content}>
        <div className={styles.headline}>Забери лучшее предложение</div>
        <div className={styles.subtitle}>Акция действует только 10 дней</div>
        <div className={styles.button}>
          <Metrika name='cta-headphone' topic={topic}>
            <Link
              to={widgetLink}
              type='blank'
              className={styles.buttonLink}
            >
              <ButtonWithIcon
                type='ghost'
                size={isMobile ? 'medium' : 'large'}
                background='linear-gradient(25deg, #B737A2 10%, #C600B0 20%, #B615B0 30%, #FD3378 60%, #FF0078 100%)'
                icon={ArrowRightRounded}
                iconPosition='right'
              >
                <span className={styles.buttonText}>{caption}</span>
              </ButtonWithIcon>
            </Link>
          </Metrika>
        </div>
      </div>
    </div>
  );
}

Card4.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
};
