import resolve from 'core/resolver/resolve';

import { denormalizeData } from 'core/utils/api';


const TOPIC_FIELDS = 'headline,list_headline,alternative_headline,link,topic_type';

const commonAnnounce = {
  limit: 1,
  include: 'rubric,image,tags',
  fields: TOPIC_FIELDS,
  sort: 'list',
};

export default resolve({
  popular: ({ bebopApi, popular, consoleError }) => {
    return popular || bebopApi
      .getTopics({
        sort: '-views_count',
        limit: 5,
        include: 'image,rubric,photo_gallery,tags',
        fields: TOPIC_FIELDS,
        visibility: 'main_page',
      })
      .then(denormalizeData)
      .catch(consoleError('popular block', []));
  },

  main({ bebopApi, main, consoleError }) {
    return main || bebopApi
      .getTopics({
        limit: 3,
        include: 'image,rubric,photo_gallery,tags',
        fields: TOPIC_FIELDS,
        list: 'main',
        sort: 'list',
      })
      .then(denormalizeData)
      .catch(consoleError('main block', []));
  },

  gallery({ bebopApi, gallery, consoleError }) {
    return gallery || bebopApi
      .getTopics({
        topic_type: 'gallery',
        include: 'image,photo_gallery',
        fields: TOPIC_FIELDS,
        list: 'gallery',
        sort: 'list',
      })
      .then(denormalizeData)
      .catch(consoleError('gallery block', []));
  },

  quote({ bebopApi, quote, consoleError }) {
    return quote || bebopApi
      .getTopics({
        limit: 1,
        include: 'content',
        fields: 'link',
        list: 'quote',
        sort: 'list',
      })
      .then(denormalizeData)
      .catch(consoleError('quote block', []));
  },

  playlist({ bebopApi, playlist, consoleError }) {
    return playlist || bebopApi
      .getTopics({
        limit: 1,
        include: 'content',
        fields: '',
        list: 'playlist',
        sort: 'list',
      })
      .then(denormalizeData)
      .catch(consoleError('playlist block', []));
  },

  unclipped({ bebopApi, unclipped, consoleError }) {
    return unclipped || bebopApi
      .getTopics({
        ...commonAnnounce,
        list: 'super-announce-unclipped',
      })
      .then(denormalizeData)
      .catch(consoleError('unclipped block', []));
  },

  clipped({ bebopApi, clipped, consoleError }) {
    return clipped || bebopApi
      .getTopics({
        ...commonAnnounce,
        list: 'super-announce-clipped',
      })
      .then(denormalizeData)
      .catch(consoleError('clipped block', []));
  },

  gameCompare: ({ bebopApi, gameCompare, consoleError }) => {
    return gameCompare || bebopApi
      .getTopics({
        limit: 1,
        include: 'content',
        fields: 'headline,list_headline,link,alternative_headline,topic_type',
        list: 'contest',
      })
      .then(denormalizeData)
      .catch(consoleError('gameCompare block', []));
  },
});
