import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';
import Button from 'core/components/Button';

import Link from 'site/components/Link';
import withEmbed from 'site/components/Embed/withEmbed';

import Listen from 'site/icons/Listen.svg';

import Images from './Images';

import styles from './index.styl';

import { SIDE_INDENT_MOBILE } from 'site/constants';


function Media(props) {
  const {
    content,
    embedMode,
    theme,
    isDesktop,
  } = props;

  const {
    artist_group: artistGroup,
    artist_person: artistPerson,
  } = content.relationships;
  const {
    hi_fi: hiFi,
    link,
  } = (artistGroup?.data || artistPerson?.data)?.attributes || {};

  const btnText = hiFi ? 'Послушать в HiFi-качестве' : 'Послушать';

  return (
    <div className={styles.media}>
      <style jsx>{`
        .${styles.media}
          :global(.mobile) &
            margin 0 ${SIDE_INDENT_MOBILE}px
        .${styles.btnWrapper}
          color ${theme.colors.layout}
          font 14px/17px ${theme.fonts.text}
      `}</style>
      {isDesktop && <Images content={content} />}
      {link && !embedMode &&
        <Link
          to={link}
          type='blank'
          className={styles.btnLink}
        >
          <Button
            background='linear-gradient(90deg, #CD3DFA 0%, #6775FC 100%)'
            radius='20px'
            type='ghost'
          >
            <div className={styles.btnWrapper}>
              <span >{btnText}</span>
              <Listen />
            </div>
          </Button>
        </Link>
      }
    </div>
  );
}

Media.propTypes = {
  theme: PropTypes.object,
  content: PropTypes.object,
  embedMode: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

export default withTheme(withBreakpoint(withEmbed(Media)));
