import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import SocializatorSkeleton from 'core/components/Socializator/Skeleton';

import { NegativeMobile, Indent } from 'site/components/Wrappers';

import styles from './index.styl';

function Star({ isMobile }) {
  return (
    <Fragment>
      <div className={styles.header}>
        <div className={cx('lb-skeleton', styles.name)} />
        <Desktop>
          <SocializatorSkeleton />
        </Desktop>
      </div>
      <Indent top={isMobile ? 20 : 30} />
      <NegativeMobile>
        <Mobile>
          <div className={cx('lb-skeleton', styles.cover)} />
        </Mobile>
        <div className={cx('lb-skeleton', styles.info)} />
      </NegativeMobile>
      <Indent top={30} />
      <div className={cx('lb-skeleton', styles.text)} />
    </Fragment>
  );
}

Star.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Star);
