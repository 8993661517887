import PropTypes from 'prop-types';

import coreMarkdown from 'core/components/TopicContent/blocks/markdown';
import EmptyWrapper from 'core/components/EmptyWrapper';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import Heading from 'core/components/Heading';

import modelPropTypes from 'core/utils/prop-types/model';

import withEmbed from 'site/components/Embed/withEmbed';

import HeadlineWrapper from './HeadlineWrapper';

function Markdown({
  block,
  parentProps,
  ctx,
  embedMode,
}) {
  const {
    isDesktop,
    disableCoreMarkdown,
  } = parentProps;

  if (!disableCoreMarkdown) return coreMarkdown(block, parentProps, ctx);

  const {
    attributes: {
      body,
    },
  } = block;

  const HeadingComponent = props => {
    // eslint-disable-next-line react/prop-types
    const { level, children } = props;

    const value = children[0];

    const Wrapper = level === 2 ? HeadlineWrapper : EmptyWrapper;

    return (
      <Wrapper elId={`_${value}`} parentProps={parentProps}>
        <Heading level={level}>
          {children}
        </Heading>
      </Wrapper>
    );
  };

  return (
    <MarkdownWrapper
      interitemSpacing={isDesktop ? 20 : 10}
      components={{
        h1: HeadingComponent,
        h2: HeadingComponent,
        h3: HeadingComponent,
        h4: HeadingComponent,
        h5: HeadingComponent,
        h6: HeadingComponent,
        ...embedMode && {
          a: ({ children }) => children,
        },
      }}
    >
      {body}
    </MarkdownWrapper>
  );
}

Markdown.propTypes = {
  block: modelPropTypes(
    PropTypes.shape({ body: PropTypes.string.isRequired })
  ).isRequired,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
  embedMode: PropTypes.bool,
};

export default withEmbed(Markdown);
