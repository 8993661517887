import { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import spoiler from 'core/components/TopicContent/blocks/spoiler';

import styles from './index.styl';

export default function Spoiler(props) {
  const {
    block,
    parentProps,
    ctx,
  } = props;

  const {
    theme: {
      colors: {
        divider,
        grey,
      },
    },
  } = parentProps;

  const [toggled, setToggled] = useState(false);

  return (
    <div className={cx(styles.spoilerContainer, toggled && '_isToggled')}>
      <style jsx>{`
        .${styles.spoilerContainer}
          &._isToggled
            background-color ${grey}
          :global(.desktop) &
            border 1px solid ${divider}
      `}</style>
      {spoiler(block, parentProps, ctx, setToggled)}
    </div>
  );
}

Spoiler.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
