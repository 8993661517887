import { useState, useCallback, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Controls from './Controls';
import Popup from './Popup';

import { getReactionFromLS } from './utils';
import { EMBED_REACTIONS_LS_KEY } from './constants';

function Reactions({ className, id, embedUrl }) {
  const [reactedReactionSlug, setReactedReactionSlug] = useState();

  const handleReacted = useCallback(slug => {
    setReactedReactionSlug(slug);
  }, [setReactedReactionSlug]);

  const handleReactedStorage = useCallback(() => {
    const reaction = getReactionFromLS(id);
    if (reaction) setReactedReactionSlug(reaction.value);
  }, [setReactedReactionSlug, id]);

  useEffect(() => {
    const handleStorage = event => event.key === EMBED_REACTIONS_LS_KEY && handleReactedStorage();

    handleReactedStorage();
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [handleReactedStorage]);

  return (
    <div className={cx('reactions', className)}>
      <Controls
        id={id}
        embedUrl={embedUrl}
        reactedReactionSlug={reactedReactionSlug}
        handleReacted={handleReacted}
        handleReactedStorage={handleReactedStorage}
      />
      <Popup
        id={id}
        slug={reactedReactionSlug}
      />
    </div>
  );
}

Reactions.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  embedUrl: PropTypes.string,
};

export default Reactions;
