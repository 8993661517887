import PropTypes from 'prop-types';
import cx from 'classnames';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';
import bindProps from 'core/components/bindProps';

import Link from 'site/components/Link';
import SmartImage from 'core/components/SmartImage';
import EmptyWrapper from 'core/components/EmptyWrapper';
import Button from 'core/components/Button';

import { isNovinki } from 'site/utils';

import Album from 'site/icons/Album.svg';

import types from './types';

import styles from './index.styl';

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
];

const requiredPayloadImports = [
  'rubric',
  'image',
  'tags',
];
const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  rubric: [],
  tags: [],
});

function CardMainHero(props) {
  const {
    content,
    theme,
    type,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: originalCover,
        super_announce: superAnnounceCover,
        thumbnail: previewCover,
      },
    },
    rubric: {
      root_title: rubricRootTitle,
      title: rubricTitle,
    },
    tags,
  } = relationships(content);

  const {
    link_attrs: linkAttrs,
    remote_image: remoteImage,
  } = content.extra || {};

  const {
    clippedImage,
    imageWidth,
    rubricWithBg,
    small,
  } = types[type] || {};

  const Wrapper = small ? 'div' : EmptyWrapper;

  const skipCropping = type === 2 || type === 3;
  const cover = clippedImage && superAnnounceCover
    ? superAnnounceCover
    : originalCover;
  const previewSrc = skipCropping ? null : previewCover;
  const rubricName = rubricTitle || rubricRootTitle;

  return (
    <div className={cx(
      styles.wrapper,
      small && styles._small,
      clippedImage && styles._isClippedImage,
      rubricWithBg && styles._rubricWithBg
    )}
    >
      <style jsx>{`
        .${styles.rubric}
          font 11px/14px ${theme.fonts.text}
          color ${theme.colors.content}
          .${styles._rubricWithBg} &
            color ${theme.colors.blue60}
            background ${theme.colors.content}

        .headline
        .${styles.altHeadline}
          color ${theme.colors.content}

        .headline
          font 23px/28px ${theme.fonts.display}
          .${styles._small} &
            font 17px/20px ${theme.fonts.display}
          .${styles._small}.${styles.card}:hover &
            color ${theme.colors.magenta60}

        .${styles.altHeadline}
          font 18px/22px ${theme.fonts.text}
          .${styles._small} &
            font 16px/20px ${theme.fonts.text}

        :global(button)
          .${styles.wrapper}:hover &
            background ${theme.colors.magenta60}
            border 1px solid ${theme.colors.magenta60}

        .${styles.waves}
          &:before
            background url('/images/waves/waves2.webp') no-repeat
            background-size cover
      `}</style>
      <Link
        to={link}
        type='secondary'
        className={styles.card}
        {...linkAttrs && {
          innerRef: node => {
            if (node) {
              linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
            }
          },
        }}
      >
        {rubricName && <div className={styles.rubric}>{rubricName}</div>}
        <div className={styles.content}>
          <div className='headline'>
            {listHeadline || headline}
          </div>
          {altHeadline &&
            <div className={styles.altHeadline}>
              {altHeadline}
            </div>
          }
          {!small &&
            <div className={styles.read}>
              <Button type='secondaryBlue' size='medium'>Читать</Button>
            </div>
          }
        </div>
        <div className={styles.image}>
          <Wrapper className={styles.imageSmallWrapper}>
            <SmartImage
              width={imageWidth}
              position='50% 100%'
              aspectRatio={(type === 0 || type === 1) ? 3 / 2 : null}
              {...skipCropping && { placement: 'contain' }}
              {...cover && {
                src: cover,
                previewSrc,
              }}
              instant={skipCropping}
              {...remoteImage && { url: remoteImage }}
            />
          </Wrapper>
        </div>
        <div className={styles.waves} />
        {isNovinki(tags) && (
          <div className={styles.album}>
            <Album />
          </div>
        )}
      </Link>
    </div>
  );
}

CardMainHero.propTypes = {
  /** Данные для карточки, соответствующие модели `topicAttributes` */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  theme: PropTypes.object.isRequired,
  /** Тип карточки */
  type: PropTypes.number,
};

CardMainHero.defaultProps = {
  type: 0,
};

const Card = skip(withTheme(CardMainHero));

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export { Card as CardMainHero0 };
export const CardMainHero1 = bindProps({ type: 1 })(Card);
export const CardMainHero2 = bindProps({ type: 2 })(Card);
export const CardMainHero3 = bindProps({ type: 3 })(Card);

export { CardMainHero as StorybookComponent };

export default Card;
