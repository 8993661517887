import PropTypes from 'prop-types';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Image from 'core/components/Image';
import CaptionCredits from 'core/components/CaptionCredits';

import resolveRelationships from 'core/utils/relationships';

import { Indent } from 'site/components/Wrappers';

import { LAYOUT_MAX_WIDTH, LAYOUT_MAX_WIDTH_MOBILE, SIDE_INDENT } from 'site/constants';

import styles from './index.styl';

const relationships = resolveRelationships(
  ['image'],
  {},
  { image: { versions: {} } },
);


function TopicCoverImage({ isDesktop, content }) {
  const {
    attributes: {
      headline,
    },
  } = content;

  const {
    image: {
      caption,
      credits,
      credits_url: creditsUrl,
      versions,
    },
  } = relationships(content);

  const indent = isDesktop ? SIDE_INDENT : 0;
  const altAndTitle = caption || headline;
  const maxWidth = isDesktop ? LAYOUT_MAX_WIDTH : LAYOUT_MAX_WIDTH_MOBILE;

  return (
    <div className={styles.coverImage}>
      <Indent left={-indent} right={-indent}>
        <div className={styles.image}>
          <Image
            versions={versions}
            widthFromSrc
            maxWidth={maxWidth}
            maxHeight={600}
            placement='contain'
            useSchema
            alt={altAndTitle}
            title={altAndTitle}
          />
        </div>
      </Indent>
      <div className={styles.caption}>
        <CaptionCredits
          caption={caption}
          credits={credits}
          creditsUrl={creditsUrl}
        />
      </div>
    </div>
  );
}

TopicCoverImage.propTypes = {
  content: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(TopicCoverImage);
