export default function getGeo(tags) {
  return tags.reduce((result, tag) => {
    const {
      root_slug: rootSlug,
    } = tag.attributes;

    if (rootSlug !== '') {
      const parentGeo = tags.find(group => group.attributes.slug === rootSlug);
      parentGeo.incoming = parentGeo.incoming ? [...parentGeo.incoming, tag] : [tag];
    } else {
      result.push(tag);
    }
    return result;
  }, []);
}

