import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.styl';
import { TOPIC_MAX_WIDTH } from 'site/constants';

export default function MaxWidthWrapper({ className, children }) {
  return (
    <div
      className={cx(styles.maxWidthWrapper, className)}
      style={{ maxWidth: TOPIC_MAX_WIDTH }}
    >
      {children}
    </div>
  );
}

MaxWidthWrapper.propTypes = {
  className: PropTypes.string,
};
