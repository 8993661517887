import PropTypes from 'prop-types';

import { YandexMetrikaGoalSender } from 'core/components/MetricsEventSender';

import { siteUrl } from 'site/constants';

export default function Metrika({ children, topic, name }) {
  const { link } = topic.content.attributes;

  return (
    <YandexMetrikaGoalSender params={{ url: siteUrl + link }} click={name}>
      {children}
    </YandexMetrikaGoalSender>
  );
}

Metrika.propTypes = {
  name: PropTypes.string,
  topic: PropTypes.object,
};
