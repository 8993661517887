import { Route } from 'core/libs/router';
import { Helmet } from 'core/libs/helmet';
import PropTypes from 'prop-types';
import { withRouter, matchPath } from 'react-router';

import configResolver from './shared-app-config';

import App from 'core/components/App';
import BaseRoutes from 'core/components/BaseRoutes';
import { topic as topicPattern } from 'core/components/BaseRoutes/routes-patterns';
import LegalPage from 'core/components/LegalPage';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Layout from 'site/components/Layout';
import { BrandingStateProvider } from 'core/components/Ad/BrandingContext';
import YaMetrikaScrollPercent from 'site/components/YaMetrikaScrollPercent';
import EmbedProvider from 'site/components/Embed/EmbedProvider';

import Main from './pages/main';
import Tag from 'site/pages/tag';
import Author from 'site/pages/author';
import Authors from 'site/pages/authors';
import Search from './pages/search';
import Topic from 'site/pages/topic';
import StarsPage from 'site/pages/stars';
import Rubric from 'site/pages/rubric';
import NewsPage from 'site/pages/news';
import About from 'site/pages/about';
import Genres from 'site/pages/genres';
import MediaPage from 'site/pages/media';
import LeaderBoard from 'site/pages/leaderboard';

import styles from './index.styl';


function Site(props) {
  const {
    location,
    isMobile,
  } = props;

  /**
   * Конфиг приложения нужно резолвить на каждую перерисовку чтоб при SSR
   * правильно резолвились переменные окружения.
   */
  const config = configResolver();
  const notTopic = !matchPath(location.pathname, topicPattern);

  return (
    <div className={styles.horScrollFixer}>
      <Helmet>
        <script>
          {`
            mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
            mindbox.queue = mindbox.queue || [];
            mindbox('create', {
              endpointId: 'zvuk-website',
            });
          `}
        </script>
        <script src='https://api.mindbox.ru/scripts/v1/tracker.js' async />
        <script>
          {`
            (function(){
              var t=document.createElement("script");
              t.type="text/javascript",t.async=!0,t.src='https://vk.com/js/api/openapi.js?169',t.onload=function(){
                VK.Retargeting.Init("VK-RTRG-1766810-9cExz"),
                VK.Retargeting.Hit()
              },document.head.appendChild(t)
            })();
          `}
        </script>
        <noscript>
          {`
            <img src="https://vk.com/rtrg?p=VK-RTRG-1766810-9cExz" style="position:fixed; left:-999px;" alt=""/>
          `}
        </noscript>
        <script>
          {`
            (function(e, x, pe, r, i, me, nt){
              e[i]=e[i]||function(){
                (e[i].a=e[i].a||[]).push(arguments)
              },
              me=x.createElement(pe),me.async=1,me.src=r,nt=x.getElementsByTagName(pe)[0],nt.parentNode.insertBefore(me,nt)
            })(window, document, 'script', 'https://abt.s3.yandex.net/expjs/latest/exp.js', 'ymab');
            ymab('metrika.88036962', 'init');
          `}
        </script>
        {isMobile && (
          <script>{`
            !function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "78da0288-a8d6-41e5-8fd3-651854372273"}});
            AF('banners', 'showBanner');
          `}</script>
        )}
      </Helmet>
      <App {...config}>
        <EmbedProvider>
          <BrandingStateProvider>
            <Layout>
              <BaseRoutes
                authors={Authors}
                author={Author}
                tags={Tag}
                main={Main}
                topic={Topic}
                about={About}
                search={Search}
                rubrics={Rubric}
                legal={LegalPage}
                leaderBoard={LeaderBoard}
              >
                <Route
                  exact
                  path='/news'
                  component={NewsPage}
                />
                <Route
                  exact
                  path='/genres'
                  component={Genres}
                />
                <Route
                  exact
                  path='/stars'
                  component={StarsPage}
                />
                <Route
                  exact
                  path='/media'
                  component={MediaPage}
                />
              </BaseRoutes>
            </Layout>
          </BrandingStateProvider>
        </EmbedProvider>
        {notTopic && <YaMetrikaScrollPercent />}
      </App>
    </div>
  );
}

Site.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(withRouter(Site));
