import seedrandom from 'seedrandom';

/**
 * В оригинальной функции на MDN вместо `prng` используется `Math.random()`.
 * Нам же нужно сгенерировать целое число, которое не будет меняться.
 * Воспользуемся алгоритмом PRNG: pseudorandom number generator.
 * И из него получаем целое число в диапазоне [min, max].
 */
const getRandomIntInclusive = (min, max, id) => {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  const prng = seedrandom(id);
  return Math.floor(prng() * (maxFloored - minCeiled + 1) + minCeiled);
};

const checkIsValidType = type => {
  if (!type) return false;
  return [1, 2, 3, 4, 5].some(number => number === Number(type));
};

const getGradientFromMarkup = markup => {
  const resultSrc = RegExp(/data-gradient=['|"]([^"']+)['|"]/).exec(markup);

  return resultSrc ? resultSrc[1] : null;
};

export default function getMusicEmbedGradientType(html, id) {
  const customType = getGradientFromMarkup(html);

  return checkIsValidType(customType)
    ? Number(customType)
    : getRandomIntInclusive(1, 5, id);
}
