/**
 * Утилита скроллит до элемента с id, указанным в 'to'.
 * Также можно указать смещение от верхней границы экрана в 'shift'.
 *
 * @author Artyom Gavrilyuk <a.gavrilyuk@rambler-co.ru>
 */
export default function scrollTo(to, shift) {
  const target = document.getElementById(to);
  if (!target) return;
  const top = target.getBoundingClientRect().top - shift;
  const scrollToValue = window.pageYOffset + top;
  window.scroll({ top: scrollToValue, behavior: 'smooth' });
}
