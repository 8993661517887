import PropTypes from 'prop-types';

import CoreLink from 'core/components/Link';

import withEmbed from 'site/components/Embed/withEmbed';

const Link = ({ embedMode, ...otherProps }) => {
  return <CoreLink {...embedMode && { target: '_blank' }}{...otherProps} />;
};

Link.propTypes = {
  embedMode: PropTypes.bool,
};

export default withEmbed(Link);
