import PropTypes from 'prop-types';

import { MaxWidthWrapper } from 'site/components/Wrappers';
import withEmbed from 'site/components/Embed/withEmbed';

import { checkNavigationHeaders } from 'site/utils';

import ContentWithContents from './ContentWithContents';

function StarsWrapper(props) {
  const { isHasContents, content, embedMode, ...otherProps } = props;

  if (!Array.isArray(content) || content.length < 1) return null;

  if (!embedMode && isHasContents && checkNavigationHeaders(content)) {
    return <ContentWithContents content={content} {...otherProps} />;
  }

  return (
    <MaxWidthWrapper>
      {props.children}
    </MaxWidthWrapper>
  );
}

StarsWrapper.propTypes = {
  isHasContents: PropTypes.bool,
  content: PropTypes.array,
  embedMode: PropTypes.bool,
};

export default withEmbed(StarsWrapper);
