import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';
import queryString from 'core/libs/query-string';

import EmbedContext from '.';

function EmbedProvider({ location: { search }, children }) {
  const queryFilters = queryString.parse(search);
  const {
    embed_mode: embedMode,
  } = queryFilters;
  return (
    <EmbedContext.Provider
      value={!!embedMode}
    >
      {children}
    </EmbedContext.Provider>
  );
}

EmbedProvider.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(EmbedProvider);
