import RobotoRegular from './Roboto/roboto-regular.woff';
import RobotoRegular2 from './Roboto/roboto-regular.woff2';
import RobotoLight from './Roboto/roboto-light.woff';
import RobotoLight2 from './Roboto/roboto-light.woff2';
import RobotoItalic from './Roboto/roboto-italic.woff';
import RobotoItalic2 from './Roboto/roboto-italic.woff2';
import RobotoMedium from './Roboto/roboto-medium.woff';
import RobotoMedium2 from './Roboto/roboto-medium.woff2';
import RobotoBold from './Roboto/roboto-bold.woff';
import RobotoBold2 from './Roboto/roboto-bold.woff2';
import RussoOneRegular from './RussoOne/RussoOne-Regular.woff';
import RussoOneRegular2 from './RussoOne/RussoOne-Regular.woff2';

const fonts = {
  text: 'Roboto, sans-serif',
  display: 'RussoOne, sans-serif',
  get nuance() {
    return this.text;
  },
  faces: [
    {
      fontFamily: 'RussoOne',
      fontWeight: 400,
      fontStyle: 'normal',
      src: `local('RussoOne-Regular'), url(${RussoOneRegular2}) format('woff2'), url(${RussoOneRegular}) format('woff')`,
    },
    {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontStyle: 'normal',
      src: `local('Roboto-Regular'), url(${RobotoRegular2}) format('woff2'), url(${RobotoRegular}) format('woff')`,
    },
    {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontStyle: 'normal',
      src: `local('Roboto-Medium'), url(${RobotoMedium2}) format('woff2'), url(${RobotoMedium}) format('woff')`,
    },
    {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontStyle: 'normal',
      src: `local('Roboto-Bold'), url(${RobotoBold2}) format('woff2'), url(${RobotoBold}) format('woff')`,
    },
    {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontStyle: 'italic',
      src: `local('Roboto-Italic'), url(${RobotoItalic2}) format('woff2'), url(${RobotoItalic}) format('woff')`,
    },
    {
      fontFamily: 'Roboto',
      fontWeight: 300,
      fontStyle: 'normal',
      src: `local('Roboto-Light'), url(${RobotoLight2}) format('woff2'), url(${RobotoLight}) format('woff')`,
    },
  ],
};

export default fonts;
