import { Fragment } from 'react';
import PropTypes from 'prop-types';

import ContentBackground from 'core/components/ContentBackground';
import Layout, { Content, Footer } from 'core/components/Layout';
import ThemeSwitch from 'core/components/ThemeSwitch';
import { Mobile, Desktop } from 'core/components/breakpoint';

import Shapka from 'site/components/Shapka';
import SiteFooter from 'site/components/Footer';

import { Billboard, Branding } from 'site/components/Ad/desktop';
import { TopBanner } from 'site/components/Ad/mobile';
import withEmbed from 'site/components/Embed/withEmbed';

const LayoutCore = ({ children }) => (
  <ContentBackground className='customBg'>
    <Layout>
      {children}
    </Layout>
  </ContentBackground>
);

function SiteLayout({ children, embedMode }) {
  if (embedMode) {
    return (
      <LayoutCore>
        <style jsx>{`
          :global(body)
            min-width unset
        `}</style>
        <Content>
          {children}
        </Content>
      </LayoutCore>
    );
  }

  return (
    <Fragment>
      <Desktop>
        <Branding />
        <Billboard />
      </Desktop>
      <Mobile>
        <TopBanner />
      </Mobile>
      <LayoutCore>
        <Shapka />
        <Content>
          {children}
        </Content>
        <Footer>
          <ThemeSwitch name='dark'>
            <SiteFooter />
          </ThemeSwitch>
        </Footer>
      </LayoutCore>
    </Fragment>
  );
}

SiteLayout.propTypes = {
  embedMode: PropTypes.bool,
};

export default withEmbed(SiteLayout);
