import EmbedContext from '.';

export default function withEmbed(Component) {
  return function EmbedConsumer(props) {
    return (
      <EmbedContext.Consumer>
        {embedMode => {
          return <Component embedMode={embedMode} {...props}  />;
        }}
      </EmbedContext.Consumer>
    );
  };
}
