import PropTypes from 'prop-types';
import { Fragment } from 'react';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { Desktop, Mobile } from 'core/components/breakpoint';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';

import Page from 'core/components/Page';
import Feed from 'core/components/Feed';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { PageIndent, Indent } from 'core/components/Wrappers';
import snippet from 'core/components/TopicContent/blocks/snippet';

import { NegativeMobile } from 'site/components/Wrappers';
import Popular from './Popular';
import Opinion from 'site/widgets/Opinion';
import MainGameCompare from './GameCompare';

import { Parallax } from 'site/components/Ad/desktop';
import { Listing } from 'site/components/Ad/mobile';

import CardVertical from 'site/cards/CardVertical';
import CardMainHero, { CardMainHero1, CardMainHero2, CardMainHero3 } from 'site/cards/CardMainHero';
import GalleryTopics from 'site/components/GalleryTopics';

import { getWidget } from 'site/utils';

import dataProvider from './mainTopics';

import styles from './index.styl';


function MainPage(props) {
  const {
    isMobile,
    popular,
    gallery,
    main,
    quote,
    playlist,
    clipped,
    unclipped,
    gameCompare,
  } = props;

  const vertical = isMobile ? 10 : 20;
  const gotQuote = !!(Array.isArray(quote) && quote[0]);
  const gotPlaylist = !!(Array.isArray(playlist) && playlist[0]);
  const musicList = gotPlaylist ? getWidget(playlist[0], 'snippet') : null;
  const opinion = gotQuote ? getWidget(quote[0], 'opinion') : null;
  const opinionLink = gotQuote ? quote[0].attributes.link : null;

  // eslint-disable-next-line react/prop-types
  const SupperAnnounce = ({ content, card }) => (
    <Indent top={vertical}>
      <Feed
        card={card}
        content={content}
      />
    </Indent>
  );

  return (
    <Page>
      <PageIndent>
        <SupperAnnounce
          content={clipped}
          card={isMobile ? CardMainHero3 : CardMainHero2}
        />
        <SupperAnnounce
          content={unclipped}
          card={isMobile ? CardMainHero1 : CardMainHero}
        />
        <Indent top={vertical}>
          <MainGameCompare topics={gameCompare} />
        </Indent>
        <Indent top={vertical}>
          <Feed
            content={main}
            card={CardVertical}
            interitemSpacing={20}
            columns={3}
            grid
          />
        </Indent>
        {gotQuote &&
          <Indent top={vertical}>
            <Opinion block={opinion} link={opinionLink} />
          </Indent>
        }
        <AdWrapper top={vertical}>
          <Desktop>
            <Parallax />
          </Desktop>
          <Mobile>
            <NegativeMobile>
              <Listing />
            </NegativeMobile>
          </Mobile>
        </AdWrapper>
        {(gallery &&  gallery.length > 0) &&
          <Fragment>
            <Desktop>
              <Indent top={vertical}>
                <div className={styles.galleryContainer}>
                  <div className={styles.galleryWrapper}>
                    <GalleryTopics topics={gallery} />
                  </div>
                </div>
              </Indent>
            </Desktop>
            <Mobile>
              <Indent top={vertical} />
              <NegativeMobile>
                <GalleryTopics topics={gallery} />
              </NegativeMobile>
            </Mobile>
          </Fragment>
        }
        <Indent top={vertical}>
          <Popular content={popular}  />
        </Indent>
        {musicList &&
          <Indent top={vertical}>
            {snippet(musicList)}
          </Indent>
        }
      </PageIndent>
    </Page>
  );
}

const topicModel = modelPropTypes(topicAttributes);

MainPage.propTypes = {
  isMobile: PropTypes.bool,
  popular: PropTypes.arrayOf(topicModel),
  unclipped: PropTypes.arrayOf(topicModel),
  clipped: PropTypes.arrayOf(topicModel),
  main: PropTypes.arrayOf(topicModel),
  quote: PropTypes.arrayOf(topicModel),
  gallery: PropTypes.arrayOf(topicModel).isRequired,
  playlist: PropTypes.arrayOf(topicModel),
  gameCompare: PropTypes.arrayOf(topicModel),
};

export default withPageHocs(dataProvider)(withBreakpoint(MainPage));
