import PropTypes from 'prop-types';

import modelPropTypes, {
  readMoreAttributes,
} from 'core/utils/prop-types/model';

import readMore from 'core/components/TopicContent/blocks/readMore';
import Feed from 'core/components/Feed';

import CardSimple, { CardSimple1S } from 'site/cards/CardSimple';

import ReadMoreSeries from './Series';
import ReadMoreWrapper from './Wrapper';


export default function ReadMore(props) {
  const {
    block,
    parentProps,
  } = props;

  const {
    theme: {
      controls: {
        vikontReadMore: {
          feed: {
            interitemSpacing,
          },
        },
      },
    },
    isDesktop,
  } = parentProps;

  const {
    attributes: {
      topic_ids: ids,
      read_more_topics: readMoreTopics,
    },
  } = block || { attributes: {} };

  if (!ids.length) return null;

  const content = block?.attributes?.read_more_topics;

  const newContent = content && content.map(topic => {
    return {
      ...topic,
      relationships: {
        image: {
          data: topic.attributes.image,
        },
      },
    };
  });

  const firstTopicType = readMoreTopics.length && readMoreTopics[0].attributes.topic_type;

  if (firstTopicType === 'series') {
    return (
      <ReadMoreSeries
        topic={readMoreTopics[0]}
        parentProps={parentProps}
      />
    );
  }

  if (isDesktop && ids.length > 2) {
    return (
      <ReadMoreWrapper parentProps={parentProps}>
        <Feed
          card={CardSimple}
          interitemSpacing={interitemSpacing}
          ids={ids}
          content={newContent}
        />
      </ReadMoreWrapper>
    );
  }

  // TODO: Сделать буллеты, если будет время

  return (
    <ReadMoreWrapper parentProps={parentProps}>
      {readMore(
        block,
        {
          singleCard: CardSimple1S,
          feedCard: CardSimple1S,
          drumCard: CardSimple1S,
        }
      )}
    </ReadMoreWrapper>
  );
}

ReadMore.propTypes = {
  block: modelPropTypes(readMoreAttributes),
  parentProps: PropTypes.object,
};
