import PropTypes from 'prop-types';

import { rawAuthorsFetcher } from 'core/fetchers/authorsPage';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';
import AuthorsPage from 'core/components/AuthorsPage';
import H1 from 'core/components/H1';

import { Indent, MaxWidthWrapper, PageIndent } from 'site/components/Wrappers';
import CardAuthorsPage from 'site/cards/CardAuthor/AuthorsPageCard';


function Authors({ rawAuthors }) {
  return (
    <PageIndent>
      <MaxWidthWrapper>
        <H1>Авторы</H1>
        <Indent top={30} bottom={60}>
          <AuthorsPage
            rawAuthors={rawAuthors}
            card={CardAuthorsPage}
            columns={1}
            interitemSpacing={10}
          />
        </Indent>
      </MaxWidthWrapper>
    </PageIndent>
  );
}

Authors.propTypes = {
  rawAuthors: PropTypes.object,
};

const dataProvider = resolve({
  rawAuthors: rawAuthorsFetcher(),
});

export default withPageHocs(dataProvider)(Authors);
