import PropTypes from 'prop-types';

import Card1 from './cards/Card1';
import Card2 from './cards/Card2';
import Card3 from './cards/Card3';
import Card4 from './cards/Card4';
import CardDefault from './cards/CardDefault';

const cardsMap = {
  default: CardDefault,
  1: Card1,
  2: Card2,
  3: Card3,
  4: Card4,
};

export default function PublicationLink({ block, parentProps }) {
  if (!block || !block.attributes.link) return null;

  const Card = cardsMap[block.attributes.credits] || cardsMap.default;

  return <Card block={block} parentProps={parentProps} />;
}

PublicationLink.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
};
