import PropTypes from 'prop-types';

import { ImageWithText } from 'core/components/TopicContent/blocks/image';
import SmartImage from 'core/components/Image';

import styles from './index.styl';

const ImageComponent = (props) => (
  <div className={styles.imgWrapper}>
    <SmartImage {...props} />
  </div>
);

export default function Image({ block, parentProps }) {
  const { creditsBefore, topic } = parentProps;
  const {
    content: {
      attributes: {
        headline,
      },
    },
  } = topic || { attributes: {} };

  return (
    <ImageWithText
      block={block}
      creditsBefore={creditsBefore}
      imageComponent={ImageComponent}
      topicTitle={headline}
    />
  );
}

Image.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
};
