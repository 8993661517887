import { compose } from 'core/libs/recompose';

import resolveRelationships from 'core/utils/relationships';

import topicProvider from 'core/resolver/data/topic';
import resolve from 'core/resolver/resolve';

import { denormalizeData } from 'core/utils/api';

import { isStarTopic } from 'site/utils';


const topicsCount = 4;

const relationships = resolveRelationships(...[
  ['tags', 'content'],
  {},
  { tags: [], content: {} },
]);

const similarTopicFetcher = (bebopApi, excludedIds, genres, count) => {
  return bebopApi
    .getTopics({
      limit: 30,
      topic_type: 'artist_person,artist_group',
      include: 'image',
      fields: 'headline,link',
      excluded_ids: excludedIds.join(','),
      ...!!genres[0] && { tag: genres[0] },
    })
    .then(denormalizeData)
    .then(data => {
      if (data.length < count && genres.length > 1) {
        return similarTopicFetcher(
          bebopApi,
          excludedIds.concat(data.map((el) => el.id)),
          genres.slice(1),
          count - data.length
        ).then(newData => data.concat(newData));
      }
      return data.sort(() => Math.random() - 0.5).slice(0, count);
    })
    .catch(() => null);
};

const additionalProvider = resolve({
  topicsWithStars: ({ bebopApi, content }) => {
    const {
      attributes: {
        topic_type: topicType,
      },
    } = content;

    if (!isStarTopic(topicType)) return null;

    const { tags } = relationships(content);

    if (!tags?.length) return null;
    const starsTag = tags.find(star => star.attributes.tag_type === 'artist');

    return bebopApi
      .getTopics({
        limit: 3,
        tag: starsTag.attributes.slug,
        topic_type: 'news',
        include: 'image,rubric,photo_gallery,tags',
        fields: 'headline,list_headline,alternative_headline,link,topic_type',
        sort: '-published_at',
      })
      .then(denormalizeData)
      .catch(() => null);
  },

  similarStars: ({ bebopApi, content }) => {
    if (!content) return null;

    const { tags } = relationships(content);
    const { id } = content;

    const genres = tags
      .filter(tag => tag.attributes.tag_type === 'genre' && tag.attributes.slug !== 'pop-muzyka')
      .map(tag => tag.attributes.slug);

    return similarTopicFetcher(bebopApi, [id], [...genres, 'pop-muzyka'], topicsCount);
  },
});

export default compose(topicProvider, additionalProvider);
