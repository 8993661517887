import PropTypes from 'prop-types';
import cx from 'classnames';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import withTheme from 'core/components/theme';
import skip from 'core/resolver/skip';
import bindProps from 'core/components/bindProps';

import Link from 'site/components/Link';
import SmartImage from 'core/components/SmartImage';

import styles from './index.styl';

const requiredPayloadFields = [
  'link',
  'headline',
];

const requiredPayloadImports = [
  'image',
];
const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});


function CardStar(props) {
  const {
    content,
    theme,
    size,
  } = props;

  if (!content) return null;

  const {
    attributes: {
      headline,
      link,
    },
  } = content;

  const {
    image: {
      versions: {
        original: cover,
      },
    },
  } = relationships(content);

  const isLarge = size === 'm';

  return (
    <Link
      to={link}
      type='secondary'
      className={cx(styles.link, isLarge && styles._isLarge)}
    >
      <style jsx>{`
        .${styles.headline}
          font 17px/20px ${theme.fonts.display}
          color ${theme.colors.primary}
      `}</style>
      <div className={styles.img}>
        <SmartImage
          aspectRatio={1}
          placement='cover'
          position='center'
          src={cover}
          width={isLarge ? 150 : 100}
        />
      </div>
      <div className={styles.headline}>
        {headline}
      </div>
    </Link>
  );
}

CardStar.propTypes = {
  /** Данные для карточки, соответствующие модели `topicAttributes` */
  content: modelPropTypes(topicAttributes),
  /** Размер карточки */
  size: PropTypes.oneOf(['s', 'm']),
  /** @ignore */
  theme: PropTypes.object,
};

CardStar.defaultProps = {
  size: 'm',
};

const Card = skip(withTheme(CardStar));

CardStar.requiredPayloadFields = requiredPayloadFields;
CardStar.requiredPayloadImports = requiredPayloadImports;

export { Card as CardStarM };
export const CardStarS = bindProps({ size: 's' })(Card);

export { CardStar as StorybookComponent };

export default Card;

