import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';
import { withNonPureBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import Link from 'site/components/Link';
import Button from 'core/components/Button';
import Feed from 'core/components/Feed';
import H2 from 'core/components/H2';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import CardSmall from 'site/cards/CardSmall';
import ListDivider from 'site/components/ListDivider';

import styles from './index.styl';


const CardWithDivider = bindProps({
  card: CardSmall,
  indent: 10,
})(ListDivider);

function NewsFeed({ content, theme, isMobile }) {
  return (
    <div className={styles.newsFeed}>
      <style jsx>{`
        .${styles.title}
          font-family ${theme.fonts.display}
      `}</style>

      <div className={styles.title}>
        <H2 is='div'>Новости</H2>
      </div>

      <Feed
        content={content}
        limit={isMobile ? '5' : '9'}
        include='tags'
        topic_type='news'
        card={isMobile ? CardWithDivider : CardSmall}
        interitemSpacing={isMobile ? 0 : 20}
        columns={3}
        grid
      />

      <div className={styles.moreButton}>
        <Link to='/news' type='secondary'>
          <Button size='medium' >
            Ещё новости
          </Button>
        </Link>
      </div>
    </div>
  );
}

NewsFeed.propTypes = {
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
  content: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
};

export default withNonPureBreakpoint(withTheme(NewsFeed));
