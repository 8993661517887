import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import { isGameCompare } from 'core/components/GameCompare/utils';

import { PageIndent } from 'site/components/Wrappers';


import GameCompare from './GameCompare';
import Star from './Star';
import Common from './Common';


const isStar = pathname => /^\/stars/.test(pathname);

function getTopicType(pathname) {
  if (isStar(pathname)) {
    return 'star';
  } else if (isGameCompare(pathname)) {
    return 'gameCompare';
  }

  return 'default';
}

const loadersMap = {
  gameCompare: GameCompare,
  star: Star,
  default: Common,
};

function Skeleton({ location }) {
  const Content = loadersMap[getTopicType(location.pathname)];

  return (
    <PageIndent>
      <Content />
    </PageIndent>
  );
}

Skeleton.propTypes = {
  location: PropTypes.object,
};

export default withRouter(Skeleton);
