import PropTypes from 'prop-types';

import Button from 'core/components/Button';
import Link from 'site/components/Link';
import { YandexMetrikaGoalSender } from 'core/components/MetricsEventSender';

import styles from './index.styl';


export default function PrimePromo({ className }) {
  return (
    <YandexMetrikaGoalSender className={className} click='knopka_sberpraym'>
      <Link
        type='blank'
        to={'https://zvuk.com/sub'}
        className={styles.promo}
      >
        <Button type='magenta' size='large'>
          <span className={styles.text}>Получить СберПрайм</span>
        </Button>
      </Link>
    </YandexMetrikaGoalSender>
  );
}

PrimePromo.propTypes = {
  className: PropTypes.string,
};
