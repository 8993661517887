export default {
  linkSmall: (theme) => {
    return {
      font: `400 14px/17px ${theme.fonts.text}`,
      mobile: {
        font: `400 12px/15px ${theme.fonts.text}`,
      },
    };
  },
  sberPromoText: (theme) => {
    return {
      font: `500 13px/15px ${theme.fonts.text}`,
    };
  },
  errorMessage: (theme) => {
    return {
      font: `400 11px/14px ${theme.fonts.text}`,
    };
  },
};
