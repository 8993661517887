import PropTypes from 'prop-types';

import { WIKI_SECTION } from 'site/constants';

import styles from './index.styl';

export default function Sections(props) {
  const {
    elId,
    children,
    parentProps,
  } = props;

  const {
    theme: {
      controls: {
        wikiContent: {
          section: {
            padding,
          },
        },
      },
    },
  } = parentProps;

  const headlineId = WIKI_SECTION + elId;
  return (
    <div
      id={headlineId}
      className={styles.section}
    >
      <style jsx>{`
        .${styles.section}
          padding-top ${padding}px
      `}</style>
      {children}
    </div>
  );
}

Sections.propTypes = {
  elId: PropTypes.string,
  parentProps: PropTypes.object,
};
