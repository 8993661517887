/* eslint-disable */
export default function main() {
  var anchors = Array.prototype.slice.call(document.querySelectorAll('a[href^="#"]'), 0);

  if (anchors) {
    anchors.forEach(anchor => {
      anchor.addEventListener('click', function(e) {
        const href = this.getAttribute('href');
        if (!href || href.length < 2) return;

        const target = document.querySelector(href);
        if (!target) return;

        e.preventDefault();

        const y = target.getBoundingClientRect().top + + window.scrollY - 100;
        window.scrollTo({ top: y, behavior: 'smooth' });
      });
    });
  }

  var acc = document.getElementsByClassName("accordion");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
        panel.style.paddingBottom = null;
      } else {
        panel.style.paddingBottom = "18px";
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  }

  var options = { month: 'long', day: 'numeric' };
  var today = new Date();
  var numberOfDaysToAdd = 30;
  var result = today.setDate(today.getDate() + numberOfDaysToAdd);
  var sub = new Date(result)
  var subtxt = sub.toLocaleDateString("ru-RU", options);

  document.getElementById('day').textContent = subtxt;

  // docSlider.init();


  const queryString = window.location.search;

  function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^#&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

  var source = getUrlVars()['utm_source'];
  var links;

  function getLink() {
    if (source == 'promokodi' || source == 'promokodus' || source == 'z2cpa' || source == 'telegram_ads') {
      links = "https://zvuk.com/sub?utm_source=sz_media";
    } else if (source == 'get4click') {
      links = "https://zvuk.com/sub?utm_source=sz_media";
    } else if (source == 'sunduchok') {
      links = "https://zvuk.com/sub?utm_source=sz_media";
    } else if (source == 'flocktory') {
      links = "https://zvuk.com/sub?utm_source=sz_media";
    } else if (source == 'pepper' || source == 'partner_3' || source == 'partner_21') {
      links = "https://zvuk.com/sub?utm_source=sz_media";
    } else if (source == 'realweb') {
      links = "https://zvuk.com/sub?utm_source=sz_media";
    } else if (source == 'yandex') {
      links = "https://zvuk.com/sub?utm_source=sz_media"
    } else {
      links = "https://zvuk.com/sub?utm_source=sz_media";
    }
  }

  getLink();

  const lBtns = document.querySelectorAll('.l-btn');

  lBtns.forEach(el => {
    el.addEventListener('click', () => {
      ym(88036962, 'reachGoal', `30-days-free-${el.getAttribute('data-count')}`);
      window.location.href = links + queryString;
    });
  });

  document.querySelectorAll('.app').forEach(el => {
    el.addEventListener('click', function() {
      window.location.href = 'https://apps.apple.com/ru/app/%D0%BC%D0%BE%D0%B9-%D0%B7%D0%B2%D1%83%D0%BA-hifi-%D0%BC%D1%83%D0%B7%D1%8B%D0%BA%D0%B0-%D0%BF%D0%BE%D0%B4%D0%BA%D0%B0%D1%81%D1%82%D1%8B/id6443779583?utm_source=sz_media&utm_medium=go';
    });
  });

  document.querySelectorAll('.gp').forEach(el => {
    el.addEventListener('click', function() {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.zvooq.openplay&hl=ru&gl=US&utm_source=sz_media&utm_medium=go';
    });
  });
}

/* eslint-enable */
