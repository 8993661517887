/**
 * Формируем данные для отображения топиков в галерее,
 * заодно отфильтровывая топики, в которых нет необходимых данных.
 */
export function createTopicsImages(result, topic) {
  if (!topic
    || !topic.relationships
    || !topic.relationships.image
    || !topic.relationships.image.data
    || !topic.relationships.photo_gallery
    || !topic.relationships.photo_gallery.data
  ) {
    console.warn(`[Gallery] Topic with id ${topic && topic.id} was dropped, please check its relationships`);
    return result;
  }

  topic.relationships.image.data.topicUrl = topic.attributes.link;
  // Сохраняем количество картинок в галерее топика. Отобраджается на главной странице
  topic.relationships.image.data.topicGalleryCount = topic.relationships.photo_gallery.data.attributes.image_count;
  topic.relationships.image.data.attributes.caption = topic.attributes.headline;
  // Убираем credits, потому-что в галерее галерей он не показывается
  delete topic.relationships.image.data.attributes.credits;
  result.push(topic.relationships.image.data);

  return result;
}
