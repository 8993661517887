import PropTypes from 'prop-types';
import cx from 'classnames';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { topicDateFormat } from 'core/utils/dates';
import bindProps from 'core/components/bindProps';
import { useFetchAndUpdateWidgets } from 'core/components/GameCompare/utils';

import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';

import Link from 'site/components/Link';
import SmartImage from 'core/components/SmartImage';
import Lightning from 'core/components/GameCompare/Lightning';

import {
  isNovinki,
  buildTagTitlesString,
} from 'site/utils';

import PhotoCamera from 'site/icons/PhotoCamera.svg';
import AlbumIcon from 'site/icons/Album.svg';

import types from './types';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
  'rubric',
  'photo_gallery',
  'tags',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
  'topic_type',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  rubric: [],
  photo_gallery: {},
  tags: [],
});

const imageMaxWidth = 500;
const slideWidthMultipliers = {
  0: {
    width: '100%',
  },
  1: {
    width: '93.3%',
    height: 5,
  },
  2: {
    width: '86.6%',
    height: 5,
  },
};


function CardVertical(props, { bebopApi }) {
  const {
    content,
    tagsFilterCallback,
    theme,
    type,
    size,
  } = props;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
    topic_type: topicType,
    published_at: publishedAt,
  } = content?.attributes || {};

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric: {
      title: rubricTitle,
      root_title: rubricRootTitle,
    },
    photo_gallery: {
      images,
    },
    tags,
  } = relationships(content) || {};

  const widgets = useFetchAndUpdateWidgets(bebopApi, content);

  const {
    link_attrs: linkAttrs,
    remote_image: remoteImage,
  } = content.extra || {};

  const {
    minimalistic,
    hasBorder,
    publishedDate,
  } = types[type] || {};

  const hasImage = !!(originalCover || remoteImage);
  const isGallery = topicType === 'gallery';
  const isImageless = !hasImage && !isGallery;
  const galleryImagesLimit = minimalistic ? 1 : 3;
  const tagsString = tagsFilterCallback
    ? buildTagTitlesString(tags, tagsFilterCallback)
    : null;
  const rubricName = rubricTitle || rubricRootTitle;

  return (
    <div
      className={cx(
        styles.wrapper,
        isGallery && styles._isGallery,
        isImageless && styles._isImageless,
        minimalistic && styles._minimalistic,
        hasBorder && styles._hasBorder,
        styles[`_size_${size}`]
      )}
    >
      <style jsx>{`
        .${styles.wrapper}
          border-color ${theme.colors.divider}
          max-width ${imageMaxWidth}px

        .rubric
          font 11px/14px ${theme.fonts.text}
          color ${theme.colors[isImageless ? 'gray40' : 'gray60']}

        .${styles.headline}
          font-family ${theme.fonts.display}
          color ${theme.colors.primary}
          .${styles._isGallery} &
            color ${theme.colors.content}
          .${styles._minimalistic} &
            color ${theme.colors.primary}
          .${styles._isImageless} &
            font-family ${theme.fonts.text}
          .${styles.wrapper}:hover &
            color ${theme.colors.blue60}
          .${styles.wrapper}:hover.${styles._isGallery} &
            color ${theme.colors.azure60}

        .${styles.altHeadline}
        .${styles.tags}
          font-family ${theme.fonts.text}
          color ${theme.colors.gray40}
          .${styles._isGallery} &
            color ${theme.colors.content}
        .time
          font 11px/14px ${theme.fonts.text}
          color ${theme.colors.layout2}

        .${styles.iconWrapper}
          color ${theme.colors.content}
      `}</style>
      <Link
        to={link}
        type='secondary'
        className={styles.link}
        {...linkAttrs && {
          innerRef: node => {
            if (node) {
              linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
            }
          },
        }}
      >
        {widgets?.length > 0 && (
          <Lightning
            imageMaxWidth={size === 'm' ? 550 : 490}
            height={215}
            widget={widgets[0]}
            progressType={4}
            lightningColor={theme.colors.primary}
            borderRadius='0'
          />
        )}
        {hasImage && !isGallery && (
          <div className={styles.imageWrapper}>
            <div className={styles.image}>
              <SmartImage
                {...originalCover && {
                  src: originalCover,
                  previewSrc: previewCover,
                }}
                {...remoteImage && { url: remoteImage }}
                aspectRatio={3 / 2}
                maxWidth={imageMaxWidth}
              />
            </div>
            {isNovinki(tags) && (
              <div className={styles.iconWrapper}>
                <AlbumIcon />
              </div>
            )}
          </div>
        )}
        {(isGallery && images && images.length > 0) && images.slice(0, galleryImagesLimit).map((image, i) => {
          const {
            id,
            attributes: {
              versions: {
                original,
                thumbnail,
              },
            },
          } = image;
          return (
            <div
              key={id}
              className={styles.images}
              style={{ width: slideWidthMultipliers[i].width }}
            >
              <SmartImage
                height={slideWidthMultipliers[i].height}
                aspectRatio={1.5}
                maxWidth={imageMaxWidth}
                {...original && {
                  src: original,
                  previewSrc: thumbnail,
                }}
              />
              <div className={styles.iconWrapper}>
                <PhotoCamera />
              </div>
            </div>
          );
        })}
        <div className={styles.content}>
          {rubricName && !minimalistic && <div className='rubric'>{rubricName}</div>}
          <div className={styles.headline}>
            {listHeadline || headline}
          </div>
          {altHeadline && !isImageless && !minimalistic && (
            <div className={styles.altHeadline}>
              {altHeadline}
            </div>
          )}
          {publishedDate && publishedAt && <span className='time'>{topicDateFormat(publishedAt)}</span>}
          {!!tagsString && !minimalistic && (
            <div className={styles.tags}>{tagsString}</div>
          )}
        </div>
      </Link>
    </div>
  );
}

CardVertical.propTypes = {
  /** Данные для карточки, соответствующие модели `topicAttributes` */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  theme: PropTypes.object,
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2]),
  /** Размер карточки */
  size: PropTypes.oneOf(['s', 'm']),
  /**
   * Коллбек, который будет передан в tags.filter().
   * Если он не передан, теги не будут отображаться в карточке.
   */
  tagsFilterCallback: PropTypes.func,
};

CardVertical.contextTypes = {
  bebopApi: PropTypes.object,
};

CardVertical.defaultProps = {
  type: 0,
  size: 'm',
};

const Card = skip(withTheme(CardVertical));

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export { Card as CardVertical0M };
export const CardVertical1M = bindProps({ type: 1, size: 'm' })(Card);
export const CardVertical1S = bindProps({ type: 1, size: 's' })(Card);

export { CardVertical as StorybookComponent };

export default Card;
