import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';

import SmartImage from 'core/components/SmartImage';

import withTheme from 'core/components/theme';

import resolveRelationships from 'core/utils/relationships';

import Gallery from 'core/components/Gallery';

import DarkCamera from 'site/icons/DarkCamera.svg';

import styles from './images.styl';


const imageMaxWidth = 500;

const slideWidthMultipliers = {
  0: {
    width: '100%',
  },
  1: {
    width: '93.3%',
    height: 5,
  },
  2: {
    width: '86.6%',
    height: 5,
  },
};

const requiredPayloadImports = [
  'image',
  'photo_gallery',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  photo_gallery: {},
});


function Media(props) {
  const {
    content,
    theme,
  } = props;

  const {
    photo_gallery: {
      data: gallery,
    },
  } = content.relationships;

  const {
    image: {
      versions: {
        original: cover,
      },
    },
    photo_gallery: {
      images: galleryImages = [],
    },
  } = relationships(content);

  const [isInFullscreen, setFullscreen] = useState(false);
  const hasCover = !!cover;
  const hasGalleryImages = galleryImages.length > 0;
  const handlePreviewClick = e => {
    e.preventDefault();
    setFullscreen(true);
  };

  return (
    <Fragment>
      {hasGalleryImages && (
        <a
          className={styles.galleryPreview}
          onClick={handlePreviewClick}
          href='#'
        >
          <style jsx>{`
            .${styles.galleryPreview}
              max-width ${imageMaxWidth}px
            .${styles.count}
              background ${theme.colors.layout}
              color ${theme.colors.primary}
              font 11px/11px ${theme.fonts.text}
          `}</style>
          {galleryImages.slice(0, 3).map((image, i) => {
            const {
              id,
              attributes: {
                versions: {
                  original,
                  thumbnail,
                },
              },
            } = image;
            return (
              <div
                key={id}
                className={styles.galleryPreviewItem}
                style={{ width: slideWidthMultipliers[i].width }}
              >
                <SmartImage
                  height={slideWidthMultipliers[i].height}
                  aspectRatio={1.5}
                  maxWidth={imageMaxWidth}
                  {...original && {
                    src: original,
                    previewSrc: thumbnail,
                  }}
                />
                <div className={styles.count}>
                  {galleryImages.length}
                  <DarkCamera />
                </div>
              </div>
            );
          })}
        </a>
      )}
      {isInFullscreen && (
        <Gallery
          photoGallery={gallery}
          onMount={(instance) => {
            instance.toggleFullScreen();
            instance.focusGallery();
          }}
          onUpdate={({ state }) => {
            if (!state.isFullscreen) {
              setFullscreen(false);
            }
          }}
        />
      )}
      {hasCover && !hasGalleryImages && (
        <div className={styles.imageWrapper}>
          <div className={styles.image}>
            <SmartImage
              aspectRatio={1.5}
              maxWidth={imageMaxWidth}
              src={cover}
              placement='contain'
              position='50% 100%'
            />
          </div>
        </div>
      )}
    </Fragment>
  );
}

Media.propTypes = {
  content: PropTypes.object,
  theme: PropTypes.object,
};

export default withTheme(Media);
