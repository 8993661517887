import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import {
  authorFetcher,
  topicsFetcher,
} from 'core/fetchers/authorPage';

import withPageHocs from 'core/components/withPageHocs';

import AuthorPage from 'core/components/AuthorPage';

import { filterRequiredParams } from 'core/utils/api';

import { MaxWidthWrapper, PageIndent } from 'site/components/Wrappers';

import CardSmall from 'site/cards/CardSmall';
import CardAuthorPage  from 'site/cards/CardAuthor/AuthorPageCard';


function Author({ author, topics }) {
  return (
    <PageIndent>
      <MaxWidthWrapper>
        <AuthorPage
          author={author}
          topics={topics}
          card={CardSmall}
          interitemSpacing={20}
          authorCard={CardAuthorPage}
          titleBuilder={a => `${a.attributes.name}: редактор новостей медиакомпании`}
          descriptionBuilder={a => `${a.attributes.name} - редактор новостей медиа «Звук». Медиакомпания, которая с вами на одной волне. Каждый день мы работаем, чтобы вы получали свежие новости из мира музыки и интересные факты о жизни любимых артистов.`}
        />
      </MaxWidthWrapper>
    </PageIndent>
  );
}

Author.propTypes = {
  topics: PropTypes.array,
  author: PropTypes.object,
};

const dataProvider = resolve({
  author: authorFetcher(),
  topics: topicsFetcher({
    limit: 15,
    topic_type: 'artist_person,artist_group,news,article',
    fields: filterRequiredParams([CardSmall], 'fields'),
  }),
});

export default withPageHocs(dataProvider)(Author);
