import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import SmartImage from 'core/components/SmartImage';

import Link from 'site/components/Link';


import styles from './index.styl';

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
];

const requiredPayloadImports = [
  'image',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
});

function CardSeries(props) {
  const {
    content,
    isMobile,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
  } = content.attributes || {};

  const {
    image: {
      versions,
    },
  } = relationships(content) || {};

  return (
    <Link
      to={link}
      type='secondary'
    >
      <div className={styles.container}>
        <style jsx>{`
          .headline
            font 400 17px/20px ${theme.fonts.display}
            color ${theme.colors.content}
          `}
        </style>
        <div className={styles.image}>
          {versions && (
            <SmartImage
              height={isMobile ? 255 : 272}
              versions={versions}
              position='50% 0'
            />
          )}
        </div>
        <div className={styles.content}>
          <div className='headline'>{listHeadline || headline}</div>
        </div>
      </div>
    </Link>
  );
}

CardSeries.propTypes = {
  /** Данные для карточки, соответствующие модели `topicAttributes` */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  isMobile: PropTypes.bool,
};

const Card = skip(withTheme(withBreakpoint(CardSeries)));

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
