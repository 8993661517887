import PropTypes from 'prop-types';
import queryString from 'core/libs/query-string';
import { Fragment } from 'react';

import resolve from 'core/resolver/resolve';

import Page from 'core/components/Page';
import ListPage from 'core/components/ListPage';
import H1 from 'core/components/H1';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import EmptyWrapper from 'core/components/EmptyWrapper';
import withPageHocs from 'core/components/withPageHocs';

import { filterRequiredParams, denormalizeData } from 'core/utils/api';
import { getOffset } from 'core/utils/url-helper';
import { replaceCurrentYear } from 'core/utils/string-helper';
import modelPropTypes, {
  topicAttributes,
  rubricAttributes,
} from 'core/utils/prop-types/model';

import { PageIndent, Indent, MaxWidthWrapper } from 'site/components/Wrappers';
import RubricTopics from 'site/components/RubricTopics';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import CardVertical from 'site/cards/CardVertical';
import CardStory from 'site/cards/CardStory';
import CardMainHero from 'site/cards/CardMainHero';
import CompareCard from 'site/cards/CompareCard';


function RubricPage(props) {
  const {
    topicsData: {
      meta,
      ...rawData
    },
    rubric,
  } = props;

  const topics = denormalizeData(rawData);

  if (!topics || !topics.length) return null;

  const {
    data: {
      attributes: {
        title,
        description,
        meta_title: metaTitle,
        meta_description: metaDescription,
      },
    },
  } = rubric;

  const titleMeta = replaceCurrentYear(metaTitle);
  const descriptionMeta = replaceCurrentYear(metaDescription);
  const topicType = topics[0].attributes.topic_type  === 'news' ? 'news' : 'others';
  const Wrapper = topicType === 'news' ? MaxWidthWrapper : EmptyWrapper;

  return (
    <Page
      title={titleMeta || `${title} — все статьи и новости`}
      description={descriptionMeta}
    >
      <PageIndent>
        <Wrapper>
          <H1>{title}</H1>
          <Indent top={20} />
          {!!description && (
            <div style={{ marginBottom: '20px' }}>
              <MarkdownWrapper>{description}</MarkdownWrapper>
            </div>
          )}
          <ListPage
            meta={meta}
            limit={RUBRICS_PAGE_LIMIT}
            content={topics}
          >
            {({ content }) => (
              <Fragment>
                <RubricTopics topics={content} topicType={topicType} />
              </Fragment>
            )}
          </ListPage>
        </Wrapper>
      </PageIndent>
    </Page>
  );
}

RubricPage.propTypes = {
  topicsData: PropTypes.shape({
    data: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
    meta: PropTypes.shape({
      filtered_count: PropTypes.number,
    }),
  }),
  rubric: PropTypes.shape({
    data: modelPropTypes(rubricAttributes),
  }),
  isMobile: PropTypes.bool,
};


const dataProvider =  resolve({
  topicsData(props) {
    const {
      bebopApi,
      topicsData,
      renderError,
      location: {
        search,
      },
      match: { params: { level_1: level1, level_2: level2 } },
    } = props;

    if (topicsData) return topicsData;

    const params = queryString.parse(search);
    const { type } = params;
    const topicType = type === 'news' ? 'news' : '-news';

    return bebopApi.
      getTopics({
        rubric: level2,
        rubric_root: level1,
        topic_type: topicType,
        limit: RUBRICS_PAGE_LIMIT,
        include: filterRequiredParams([CardVertical, CardMainHero, CardStory, CompareCard], 'include'),
        fields: filterRequiredParams([CardVertical, CardMainHero, CardStory, CompareCard], 'fields'),
        with_filtered_count: 1,
        offset: getOffset(search, RUBRICS_PAGE_LIMIT),
      })
      .catch(renderError);
  },
  rubric(props) {
    const {
      bebopApi,
      rubric,
      renderError,
      match: { params: { level_1: level1, level_2: level2 } },
    } = props;
    return rubric || bebopApi
      .getRubric({
        rubric_slug: level2 ? level2 : level1,
        root_slug: level1,
      })
      .catch(renderError);
  },
});

export default withPageHocs(dataProvider)(RubricPage);
