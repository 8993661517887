import PropTypes from 'prop-types';

import getImageUrl from 'core/utils/smart-image';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import FullWidth from 'core/components/FullWidth';

import { MaxWidthWrapper } from 'site/components/Wrappers';

import styles from './index.styl';


export default function QuizWrapper({ children, backgroundImage }, { webdavHost, thumborHost }) {
  const Topic = () => (
    <MaxWidthWrapper>
      {children}
    </MaxWidthWrapper>
  );

  const backgroundUrl = backgroundImage ?
    getImageUrl({
      src: backgroundImage,
      dpr: 2,
      smart: true,
      maxWidth: 2000,
      maxHeight: 2000,
      webdavHost,
      thumborHost,
    }) :
    null;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.waves}
          background-image url(/images/waves/waves-bw.webp)
          &.${styles._customBg}
            background-image url('${backgroundUrl}')
      `}</style>
    </scope>
  );

  return (
    <FullWidth className={scope.wrapClassNames(styles.waves, backgroundUrl && styles._customBg)}>
      <Topic />
      <scope.styles />
    </FullWidth>
  );
}

QuizWrapper.contextTypes = {
  webdavHost: PropTypes.string,
  thumborHost: PropTypes.string,
};

QuizWrapper.propTypes = {
  children: PropTypes.node,
  backgroundImage: PropTypes.object,
};
