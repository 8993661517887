import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './index.styl';


function NewsDivider({ className }) {
  return (
    <div className={cx(styles.divider, className)} />
  );
}

NewsDivider.propTypes = {
  className: PropTypes.string,
};

export default NewsDivider;
