import PropTypes from 'prop-types';

import coreSnippet from 'core/components/TopicContent/blocks/snippet';

import EmbedGradient from 'site/components/EmbedGradient';

import Reactions from 'site/components/Reactions';

import { checkIsMusicEmbed, getSrcFromMarkup } from './utils';

import './index.styl';
import styles from './snippet.styl';

export default function Snippet({ block, parentProps, ctx }) {
  const html = block.attributes.html;
  const isMusicEmbed = checkIsMusicEmbed(html);

  return (
    <>
      {isMusicEmbed && <EmbedGradient block={block} topic={parentProps.topic.content} />}
      <div className={styles.snippet}>
        {coreSnippet(block, parentProps, ctx)}
      </div>
      {isMusicEmbed && (
        <Reactions
          className={styles.reactions}
          id={block.id}
          embedUrl={getSrcFromMarkup(html)}
        />
      )}
    </>
  );
}

Snippet.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
