import cx from 'classnames';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import Button from 'core/components/Button';
import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import scrollTo from 'site/utils/scroller';

import { WIKI_SECTION } from 'site/constants';

import styles from './index.styl';

function NavigationItems(props) {
  const {
    content,
    activeId,
    theme,
    isDesktop,
  } = props;

  const {
    controls: {
      shapka: {
        height: shapkaHeight,
      },
    },
    colors: {
      gray40,
      magenta60,
      primary,
    },
    animations: {
      hover: hoverAnimations,
    },
  } = theme;

  return (
    <Fragment>
      {content.map((el, i) => {
        const {
          type,
          attributes: {
            body,
          },
        } = el;
        if (type !== 'markdown') return null;
        return (
          <Markdown
            allowedElements={['h2', 'text']}
            key={i}
            children={body}
            components={{
              // eslint-disable-next-line react/prop-types
              h2({ children, level }) {
                if (level !== 2) return null;

                const value = children[0];

                const elId = WIKI_SECTION + `_${value}`;
                const isActive = elId === activeId;
                return (
                  <div className={styles.item}>
                    <Button
                      type={isDesktop ? 'wiki1' : 'wiki2'}
                      state={isActive ? 'active' : 'idle'}
                      onClick={() => {
                        scrollTo(
                          elId,
                          shapkaHeight,
                        );
                      }}
                    >
                      <style jsx>{`
                        .${styles.buttonContent}
                          transition border-bottom-color ${hoverAnimations}
                          &._isActive
                            border-bottom-color ${magenta60}
                          &:not(&._isActive)
                            border-bottom-color ${gray40}
                            :global(.desktop) &
                              border-bottom-color ${primary}
                      `}</style>
                      <div className={cx(styles.buttonContent, isActive && '_isActive')}>
                        {value}
                      </div>
                    </Button>
                  </div>
                );
              },
            }}
          />
        );
      })}
    </Fragment>
  );
}

NavigationItems.propTypes = {
  content: PropTypes.array,
  style: PropTypes.object,
  activeId: PropTypes.string,
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
};

export default withTheme(withBreakpoint(NavigationItems));
