import cx from 'classnames';
import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import bindProps from 'core/components/bindProps';
import { useFetchAndUpdateWidgets } from 'core/components/GameCompare/utils';

import withTheme from 'core/components/theme';
import SmartImage from 'core/components/SmartImage';
import Lightning from 'core/components/GameCompare/Lightning';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Link from 'site/components/Link';

import { isNovinki } from 'site/utils';

import PhotoCamera from 'site/icons/PhotoCamera.svg';
import Album from 'site/icons/Album.svg';

import types from './types';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
  'tags',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'topic_type',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  tags: [],
});

function CardStory(props, { bebopApi }) {
  const {
    content,
    theme,
    type,
    isMobile,
  } = props;

  const {
    link,
    headline,
    alternative_headline: altHeadline,
    topic_type: topicType,
  } = content?.attributes || {};

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    tags,
  } = relationships(content) || {};

  const widgets = useFetchAndUpdateWidgets(bebopApi, content);

  const {
    narrow,
    aspectRatio,
  } = types[type] || {};

  const {
    link_attrs: linkAttrs,
    remote_image: remoteImage,
  } = content.extra || {};

  const hasNovinki = isNovinki(tags);
  const withIcon = topicType === 'gallery' || hasNovinki;
  const Novinki = hasNovinki ? Album : null;

  return (
    <Link
      to={link}
      type='secondary'
      className={styles.link}
      {...linkAttrs && {
        innerRef: node => {
          if (node) {
            linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
          }
        },
      }}
    >
      <div className={cx(styles.container, narrow && styles._narrow)}>
        <style jsx>{`
          .headline
            color ${theme.colors.magenta60}
            font 21px/20px ${theme.fonts.display}
            .${styles.container}:hover &
              color ${theme.colors.azure60}

          .${styles.altHeadline}
            font 16px/20px ${theme.fonts.text}

          .${styles.icon}
            color ${theme.colors.content}
        `}
        </style>
        <div className={styles.image}>
          {widgets?.length > 0 ?
            <Lightning
              imageMaxWidth={isMobile ? 550 : 490}
              widget={widgets[0]}
              progressType={4}
              lightningColor={theme.colors.primary}
              borderRadius='0'
              height={isMobile ? 280 : 326.66}
            />
            :
            <SmartImage
              {...originalCover && {
                src: originalCover,
                previewSrc: previewCover,
              }}
              {...remoteImage && { url: remoteImage }}
              aspectRatio={aspectRatio}
              maxWidth={narrow ? 290 : 490}
            />
          }
        </div>
        <div className={styles.content}>
          {withIcon && (
            <div className={styles.icon}>
              {topicType === 'gallery' ? <PhotoCamera /> : <Novinki />}
            </div>
          )}
          <div className='headline'>{headline}</div>
          {altHeadline && <div className={styles.altHeadline}>{altHeadline}</div>}
        </div>
      </div>
    </Link>
  );
}

CardStory.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** Данные для карточки, соответствующие модели `topicAttributes` */
  content: modelPropTypes(topicAttributes),
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1]),
  /** @ignore */
  isMobile: PropTypes.bool,
};

CardStory.contextTypes = {
  bebopApi: PropTypes.object,
};

CardStory.defaultProps = {
  type: 0,
};

const Card = withTheme(withBreakpoint(CardStory));

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export { Card as CardStory0 };
export const CardStoryType1 = bindProps({ type: 1 })(Card);

export { CardStory as StorybookComponent };

export default Card;
