import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';


export default function Noindex({ topicsLength }) {
  if (topicsLength < 10) {
    return (
      <Helmet>
        <meta name='robots' content='noindex' />
      </Helmet>
    );
  }

  return null;
}

Noindex.propTypes = {
  topicsLength: PropTypes.number,
};
