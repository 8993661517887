import deepmerge from 'core/libs/deepmerge';

import {
  SIDE_INDENT_MOBILE,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import { CardSimple0 } from 'site/cards/CardSimple';
import CompareCard from 'site/cards/CompareCard';

import fonts from './fonts';


export default function baseMobileTheme(atoms) {
  const texts = {
    lead: {
      font: `bold 18px/25px ${fonts.display}`,
    },

    body: {
      font: `16px/24px ${fonts.text}`,
    },

    // h1 в топике
    title1: {
      font: `400 20px/27px ${fonts.display}`,
    },

    // рубрики
    title2: {
      font: `400 23px/28px ${fonts.display}`,
    },

    // нотный стан
    title3: {
      font: `400 17px/20px ${fonts.display}`,
    },

    // h4 в about
    title4: {
      font: `400 14px/16px ${fonts.text}`,
    },

    incut: {
      font: `400 17px/21px ${fonts.text}`,
    },

    caption1: {
      font: `12px/14px ${fonts.text}`,
    },
  };

  const vikontReadMore = {
    block: {
      display: 'flex',
    },
    title: {
      letterSpacing: '0.08em',
      marginBottom: '10px',
    },
    feed: {
      interitemSpacing: 20,
    },
    drum: {
      itemWidth: '95%',
    },
  };

  const vikontSpoiler = {
    block: {
      innerIndent: '15px 20px',
    },
    title: {
      font: `700 16px/24px ${fonts.text}`,
    },
  };

  const vikontIncut = {
    font: `400 19px/23px ${fonts.display}`,
    padding: '20px 0',
  };

  const socializator = {
    buttonHeight: 30,
  };

  const scooter = {
    padding: `0 ${SIDE_INDENT_MOBILE}px`,
    logo: {
      width: 100,
      height: 25,
    },
    social: {
      margin: 0,
      size: 30,
      iconSize: 14,
    },
  };

  const gallery = {
    thumb: {
      active: {
        borderColor: '#FE3A8A',
      },
    },
  };

  const shapka = {
    background: `url(/images/waves/waves-crispy.webp) no-repeat -47px -52px / 145%, ${atoms.colors.primary}`,
    logo: {
      width: 157,
      height: 50,
    },
    height: 50,
    padding: `20px ${SIDE_INDENT_MOBILE}px`,
    dropdown: {
      link: {
        padding: '17px 0',
        font: `normal 20px/1.25 ${fonts.text}`,
      },
    },
    menu: {
      centered: true,
      itemDivider: 'none',
    },
    sticky: {
      padding: `10px ${SIDE_INDENT_MOBILE}px`,
      height: '70px',
    },
  };

  const captionCredits = {
    margin: '10px 20px 0',
    padding: 0,
    caption: {
      font: `12px/15px ${fonts.text}`,
    },
  };

  const wikiContent = {
    section: {
      padding: 0,
    },
    navigation: {
      margin: 0,
    },
  };

  const topicFooter = {
    block: {
      spacing: 20,
      interitemSpacing: 5,
    },
  };

  const topicTopline = {
    font: `11px/14px ${fonts.text}`,
    color: '#AAA',
    rubric: {
      padding: '0px',
    },
  };

  const errorPageParams = {
    title: {
      font: `bold 22px/29px ${fonts.display}`,
    },
    subtitle: {
      font: `14px/16px ${fonts.text}`,
    },
    card: CardSimple0,
  };

  const layout = {
    indents: {
      top: VERTICAL_INDENT_MOBILE,
      right: SIDE_INDENT_MOBILE,
      bottom: VERTICAL_INDENT_MOBILE,
      left: SIDE_INDENT_MOBILE,
    },
  };

  const pages = {
    error: errorPageParams,
    notFound: errorPageParams,
  };

  const vikontSideBySide = {
    image: {
      useVerticalImages: false,
    },
    caption: {
      useVerticalCaptions: true,
      sideColor: atoms.colors.gray40,
    },
  };

  const topicHeaderTexts = {
    title: {
      font: `400 20px/27px ${fonts.display}`,
    },
    subtitle: {
      font: `300 16px/20px ${fonts.text}`,
    },
    lead: texts.body,
  };

  const gameCompare = {
    compare: {
      margin: '0 auto',
      progressType: 3,
    },
    leaderBoard: {
      card: CompareCard,
      caption: `300 15px/22px ${fonts.text}`,
      modalWindow: {
        color: atoms.colors.primary,
      },
    },
    concidences: {
      font: `400 15px/19px ${fonts.text}`,
      margin: '0 0 15px',
    },
    winner: {
      borderRadius: 0,
      viewBox: '0 0 870 1200',
      padding: '20px 15px',
    },
    share: {
      marginTop: 0,
    },
  };

  return deepmerge({
    controls: {
      vikontReadMore,
      socializator,
      shapka,
      captionCredits,
      wikiContent,
      topicFooter,
      topicTopline,
      scooter,
      gallery,
      texts,
      vikontSpoiler,
      vikontSideBySide,
      vikontIncut,
      topicHeaderTexts,
      gameCompare,
    },
    pages,
    layout,
  }, atoms);
}
