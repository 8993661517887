import PropTypes from 'prop-types';
import { Fragment } from 'react';

import resolve from 'core/resolver/resolve';

import { rawContentFetcher } from 'core/fetchers/searchPage';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';

import SearchPage from 'core/components/SearchPage';

import { PageIndent, Indent } from 'site/components/Wrappers';
import ListDivider from 'site/components/ListDivider';

import CardSmall, { CardSmallM } from 'site/cards/CardSmall';

import filters from './filters';

const MobileCard = (props) => (
  <Fragment>
    <Indent top={10} bottom={10}>
      <CardSmall {...props} />
    </Indent>
    <ListDivider />
  </Fragment>
);

const DesktopCard = (props) => (
  <Fragment>
    <Indent top={15} bottom={15}>
      <CardSmallM {...props} />
    </Indent>
    <ListDivider />
  </Fragment>
);

const requiredPayloadImports = ['tags', 'rubric', 'search_result'];
MobileCard.requiredPayloadImports = requiredPayloadImports;
DesktopCard.requiredPayloadImports = requiredPayloadImports;

/**
 * Результаты поиска на стейджах не будут соответствовать действительности,
 * потому что апишка поиска работает только с продовыми данными.
 * Для стейджей будут выдаваться рандомный список топиков.
 */
function Search({ rawContent, isMobile }) {
  return (
    <PageIndent>
      <SearchPage
        rawContent={rawContent}
        filters={filters}
        card={isMobile ? MobileCard : DesktopCard}
        interitemSpacing={0}
        tagTypeActive='secondaryGrey'
        tagType='ghost'
        tagSize='medium'
      />
    </PageIndent>
  );
}

Search.propTypes = {
  rawContent: PropTypes.array,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  rawContent: rawContentFetcher({ card: CardSmall }),
});

export default withPageHocs(dataProvider)(withBreakpoint(Search));
