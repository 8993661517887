import brandColors from './brandColors';


const colors = {
  ...brandColors,
  primary: '#000', // Основной цвет, текст
  layout: '#fff', // цвет фотна body
  layout2: '#aaaaaa',
  grey: '#f5f5f5', // цвет фона Спойлера, По теме
  content: '#fff', // цвет фона контентной области
  credits: '#aaa',
  divider: '#eaeaea', // рамки, разделители
  error: '#fe3a3a', // ошибки
  quotes: '#fe3a8a', // кавычки в виджете Мнение
  violet: '#C5B6FE', // кнопка на залипухе
  get active1() {
    return this.blue60;
  },
  get active2() {
    return this.active1;
  },
  get accent() {
    return this.active1;
  },
  get error2() { // Ошибка в галерее + модальном окне
    return this.gray30;
  },
  get caption() {
    return this.gray40;
  },
  get hint() { // Подписи к тексту, рубрики, доп. информация
    return this.gray40;
  },
  get placeholder() {
    return this.gray70;
  },
  get theme() { // цвет темы, meta theme-color, tile-color, safari-pinned-tab
    return this.active1;
  },
};

const link = {
  idle: {
    color: brandColors.white,
  },
  hover: {
    color: brandColors.azure60,
  },
  active: {
    color: brandColors.azure60,
  },
  current: {
    color: brandColors.azure60,
  },
};

const shapka = {
  link,
  dropdown: {
    more: link,
    link: {
      idle: {
        color: brandColors.black,
      },
      hover: {
        color: brandColors.blue60,
      },
      active: {
        color: brandColors.blue60,
      },
      current: {
        color: brandColors.blue60,
      },
    },
  },
};

export default {
  colors,
  controls: {
    shapka,
  },
};
