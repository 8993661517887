import PropTypes from 'prop-types';

import Button from 'core/components/Button';
import Link from 'site/components/Link';

import Metrika from '../../Metrika';

import styles from './index.styl';

export default function Card2({ block, parentProps }) {
  const {
    isMobile,
    topic,
  } = parentProps;

  if (!block || !block.attributes.link) return null;

  const {
    link: widgetLink,
    caption,
  } = block.attributes;

  return (
    <div className={styles.container}>
      <div className={styles.button}>
        <Metrika name='cta_kupit' topic={topic}>
          <Link
            to={widgetLink}
            type='blank'
            className={styles.buttonLink}
          >
            <Button
              type='ghost'
              size={isMobile ? 'medium' : 'large'}
              background='linear-gradient(162deg, #0097FB -14.39%, rgba(171, 251, 0, 0) 60%), linear-gradient(180deg, #66F771 0%, #14A73E 100%)'
            >
              <span className={styles.buttonText}>{caption}</span>
            </Button>
          </Link>
        </Metrika>
      </div>
    </div>
  );
}

Card2.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
};
