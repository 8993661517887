import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import TopicCoverImage from './TopicCoverImage/index';
import TopicCoverGallery from './TopicCoverGallery';

const topicTypeMap = {
  news: TopicCoverImage,
  article: TopicCoverImage,
  gallery: TopicCoverGallery,
};

export default function TopicCover({ content, galleryFeedContent }) {
  const topicType = content.attributes.topic_type;
  const coverType = topicTypeMap[topicType];

  if (!coverType) return null;

  const Cover = topicTypeMap[topicType];

  return (
    <Cover galleryFeedContent={galleryFeedContent} content={content} />
  );
}

TopicCover.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  galleryFeedContent: PropTypes.array,
};
