import resolve from 'core/resolver/resolve';

import Page from 'core/components/Page';
import { PageIndent } from 'core/components/Wrappers';
import withPageHocs from 'core/components/withPageHocs';

import ListPageWithFilters from 'site/components/ListPageWithFilters';

import {
  listPageTopicsFetcher,
  listPageSymbolsFetcher,
  listPageGeoFetcher,
  listPageGenreFetcher,
} from 'site/fetchers/listPage';


const title = 'Биографии исполнителей и истории музыкальных групп';
const description = 'Биографии, истории из жизни российских и зарубежных музыкальных исполнителей и групп. Только подлинная и достоверная информация о звездах музыкальной индустрии.';

function StarsPage(dataProps) {
  return (
    <Page title={title} description={description}>
      <PageIndent>
        <ListPageWithFilters {...dataProps} />
      </PageIndent>
    </Page>
  );
}

const dataProvider =  resolve({
  topicsData: listPageTopicsFetcher(),
  symbols: listPageSymbolsFetcher(),
  geo: listPageGeoFetcher(),
  genre: listPageGenreFetcher(),
});

export default withPageHocs(dataProvider)(StarsPage);
