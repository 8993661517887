import PropTypes from 'prop-types';
import cx from 'classnames';
import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import Scroller from 'core/components/Scroller';
import { Indent } from 'core/components/Wrappers';

import themePropTypes from 'core/utils/prop-types/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import styles from './index.styl';

const PSEUDO_DRUM_SIZE = 3;

function Skeleton({ isMobile, theme }) {
  const { indents } = theme.layout;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .scroller
          margin-left -${indents.left}px
          padding-left ${indents.left}px
      `}</style>
    </scope>
  );

  return (
    <>
      <div className={cx(styles.header, 'lb-skeleton')} />
      <Indent right={isMobile ? -indents.right : 0} top={isMobile ? 20 : 27}>
        <Scroller
          className={scope.wrapClassNames('scroller')}
          dataQa='skeleton'
          disabled
        >
          {Array.from(Array(PSEUDO_DRUM_SIZE)).map((empty, index) => (
            <div key={index} className={cx(styles.card, 'lb-skeleton')} />
          ))}
        </Scroller>
        <scope.styles />
      </Indent>
    </>
  );
}

Skeleton.propTypes = {
  isMobile: PropTypes.bool,
  theme: themePropTypes(`{
    layout: {
      indents: {
        left,
        right
      },
    },
  }`),
};


export default withTheme(withBreakpoint(Skeleton));
