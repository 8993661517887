import { Fragment } from 'react';

import bindProps from 'core/components/bindProps';

import Ad from 'core/components/Ad/Adfox';

import { withBrandingState } from 'core/components/Ad/BrandingContext';


// eslint-disable-next-line import/prefer-default-export
export const Billboard = bindProps({
  name: 'Billboard',
  ownerId: 406871,
  params: {
    pp: 'g',
    ps: 'fodq',
    p2: 'y',
    insertAfter: 'undefined',
    insertPosition: '0',
    stick: false,
    stickTo: 'auto',
    stickyColorScheme: 'light',
  },
})(Ad);

export const Branding = withBrandingState(({ brandingState: { rendered, setRendered } }) => (
  <Fragment>
    {rendered && <div className='brandingSpacer' style={{ marginTop: 250 }} />}
    <Ad
      name='Branding'
      ownerId={406871}
      params={{
        pp: 'g',
        ps: 'fodq',
        p2: 'hiur',
        insertAfter: 'undefined',
        insertPosition: '0',
        stick: true,
        stickTo: 'top',
        stickyColorScheme: 'light',
      }}
      onRender={() => setRendered(true)}
    />
  </Fragment>
));

export const Parallax = bindProps({
  name: 'Parallax',
  ownerId: 406871,
  params: {
    pp: 'g',
    ps: 'fodq',
    p2: 'hokm',
  },
})(Ad);

export const Ad240x400 = bindProps({
  name: 'Ad240x400',
  ownerId: 406871,
  params: {
    pp: 'g',
    ps: 'fodq',
    p2: 'hiut',
    insertAfter: 'undefined',
    insertPosition: '0',
    stick: false,
    stickTo: 'top',
    stickyColorScheme: 'light',
  },
})(Ad);
