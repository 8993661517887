import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { denormalizeData } from 'core/utils/api';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Feed from 'core/components/Feed';
import { Block, Section } from 'core/components/Grid';
import { SimpleLoader } from 'core/components/Loader';

import { CardVertical1M, CardVertical1S } from 'site/cards/CardVertical';

import Wrapper from './Wrapper';
import Error from './Error';


function GalleryFeed(props, { bebopApi }) {
  const {
    excludedId,
    isMobile,
    isFullscreen,
  } = props;

  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let ignore = false;
    bebopApi
      .getTopics({
        limit: 2,
        topic_type: 'gallery',
        include: 'image,photo_gallery',
        fields: 'link,headline,list_headline,alternative_headline,topic_type',
        ...excludedId && { excluded_ids: excludedId },
      })
      .then(data => {
        if (!ignore) {
          setTopics(denormalizeData(data));
          setLoading(false);
        }
      })
      .catch(e => {
        if (!ignore) {
          console.error(e);
          setLoading(false);
        }
      });
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludedId]);

  if (loading) return <SimpleLoader />;

  if (!topics.length) {
    return (
      <Wrapper isFullscreen={isFullscreen} isError>
        <Error isFullscreen={isFullscreen} />
      </Wrapper>
    );
  }
  if (isMobile) {
    return (
      <Wrapper isFullscreen={isFullscreen}>
        <Section>
          <Block>
            <CardVertical1S content={topics[0]} />
          </Block>
          <Block>
            <CardVertical1S content={topics[1]} />
          </Block>
        </Section>
      </Wrapper>
    );
  }

  return (
    <Wrapper isFullscreen={isFullscreen}>
      <Feed
        content={topics}
        card={CardVertical1M}
        interitemSpacing={10}
        columns={2}
        grid
      />
    </Wrapper>
  );
}

GalleryFeed.propTypes = {
  excludedId: PropTypes.string,
  isFullscreen: PropTypes.bool,
  isMobile: PropTypes.bool,
};

GalleryFeed.contextTypes = {
  bebopApi: PropTypes.object,
};

export default withBreakpoint(GalleryFeed);
