import deepmerge from 'core/libs/deepmerge';
import color from 'core/libs/color';

import Logo from 'site/icons/LogoWhite';
import ArrowLeft from 'site/icons/ArrowLeft';
import ArrowRight from 'site/icons/ArrowRight';
import ArrowRegularSharp from 'site/icons/ArrowRegularSharp';
import Video from 'site/icons/Video';
import Photo from 'site/icons/Photo';
import Search from 'site/icons/Search';
import { NegativeMobile } from 'site/components/Wrappers';

import VS from 'site/icons/VS.svg';
import Winner from 'site/icons/Winner.svg';

import {
  SIDE_INDENT,
  VERTICAL_INDENT,
} from 'site/constants';
import fonts from './fonts';

import Share from 'site/icons/Share.svg';

import { CardVertical1S } from 'site/cards/CardVertical';
import CompareCard from 'site/cards/CompareCard';

const icons = {
  logo: Logo,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  search: Search,
  Video,
  Photo,
};


export default function baseTheme(atoms) {
  const layout = {
    maxWidth: '1080px',
    sideColumnIndent: '40px',
    contentColumnMaxWidth: '780px',
    boxShadow: 'none',
    indents: {
      top: VERTICAL_INDENT,
      right: SIDE_INDENT,
      bottom: VERTICAL_INDENT,
      left: SIDE_INDENT,
    },
  };

  const animations = {
    hover: '400ms ease-in-out',
  };

  const texts = {
    lead: {
      font: `bold 23px/28px ${fonts.display}`,
    },

    body: {
      font: `18px/27px ${fonts.text}`,
    },

    // h1 в топике
    title1: {
      font: `400 32px/38px ${fonts.display}`,
    },

    // рубрики
    title2: {
      font: `400 23px/28px ${fonts.display}`,
    },

    // нотный стан
    title3: {
      font: `400 21px/26px ${fonts.display}`,
    },

    // h4 в about
    title4: {
      font: `700 18px/24px ${fonts.text}`,
    },

    incut: {
      font: `400 23px/28px ${fonts.text}`,
    },

    caption1: {
      font: `14px/16px ${fonts.text}`,
    },
  };

  const link = {
    article: {
      idle: {
        color: atoms.colors.active1,
        decoration: 'none',
      },
      hover: {
        color: atoms.colors.active1,
        decoration: 'underline',
      },
      active: {
        color: atoms.colors.active1,
        decoration: 'none',
      },
      visited: {
        color: false,
        decoration: false,
      },
    },

    primary: {
      idle: {
        color: atoms.colors.primary,
        decoration: 'underline',
      },

      hover: {
        color: atoms.colors.primary,
        decoration: 'none',
      },

      active: {
        color: atoms.colors.primary,
        decoration: 'none',
      },

      visited: {
        color: atoms.colors.primary,
        decoration: 'underline',
      },
    },

    secondary: {
      idle: {
        decoration: 'none',
      },
      hover: {
        decoration: 'none',
      },
      active: {
        decoration: 'none',
      },
      visited: {
        color: false,
        decoration: false,
      },
    },

    opinion: {
      idle: {
        color: atoms.colors.primary,
        decoration: 'none',
      },
      hover: {
        color: atoms.colors.azure60,
        decoration: 'none',
      },
      active: {
        color: atoms.colors.primary,
        decoration: 'none',
      },
      visited: {
        color: atoms.colors.primary,
        decoration: 'none',
      },
    },

    genres: {
      idle: {
        color: atoms.colors.active1,
        decoration: 'underline',
      },
      hover: {
        color: atoms.colors.active1,
        decoration: 'none',
      },
      active: {
        color: atoms.colors.active1,
        decoration: 'none',
      },
      visited: {
        color: false,
        decoration: false,
      },
    },

    footer: {
      idle: {
        color: atoms.colors.gray40,
        decoration: 'none',
      },

      hover: {
        color: atoms.colors.active1,
        decoration: 'none',
      },

      active: {
        color: color(atoms.colors.active1).mix(color(atoms.colors.primary), 0.2).string(),
        decoration: 'none',
      },

      visited: {
        color: atoms.colors.gray40,
        decoration: 'none',
      },
    },
  };

  const shapka = {
    background: 'transparent',
    logo: {
      width: 156,
      height: 60,
    },
    padding: `27px ${SIDE_INDENT}px 10px`,
    link: {
      padding: '6px 1.3vw',
    },
    shadow: 'none',
    dropdown: {
      padding: '5px 10px 5px 0',
      marginTop: 0,
      link: {
        padding: '5px 15px',
      },
    },
  };

  const scooter = {
    padding: `0 ${SIDE_INDENT}px`,
    logo: {
      width: 170,
      height: 60,
    },
    social: {
      margin: '0 0 0 10px',
      size: 30,
      iconSize: 14,
    },
  };

  const vikontSpoiler = {
    title: {
      color: atoms.colors.active1,
      font: `700 18px/23px ${fonts.text}`,
    },
    icons: {
      arrowRegularSharp: ArrowRegularSharp,
    },
    block: {
      outerIndent: 0,
      innerIndent: '20px',
      interitemSpacing: 0,
      unbordered: true,
    },
    body: {
      padding: '0 20px 20px',
    },
  };

  const vikontSideBySide = {
    image: {
      interimageSpacing: 0,
      leftImage: {
        borderRadius: '16px 0 0 16px',
      },
      rightImage: {
        borderRadius: '0 16px 16px 0',
      },
    },
  };

  const vikontReadMore = {
    block: {
      background: atoms.colors.grey,
      padding: 0,
      borderRadius: 8,
    },
    title: {
      color: color(atoms.colors.primary).alpha(0.5).string(),
      font: `400 14px/1.2 ${fonts.nuance}`,
      textTransform: 'none',
      letterSpacing: '0.08em',
      marginBottom: '20px',
      textAlign: 'center',
    },
    drum: {
      arrowsShift: -15,
      arrowsShiftVertical: 70,
      itemWidth: '45%',
      spaceBetween: 20,
      sideIndent: 0,
    },
    feed: {
      interitemSpacing: 30,
    },
  };

  const vikontIncut = {
    borderWidth: '1px 0 1px 0',
    padding: '30px 40px',
    font: `400 19px/23px ${fonts.display}`,
  };

  const topicTopline = {
    font: `12px/18px ${fonts.text}`,
    color: '#6B6B6B',
    rubric: {
      font: 'inherit',
      color: '#AAA',
      border: 'none',
      padding: '3px 8px',
      hover: {
        background: color(atoms.colors.active2).alpha(0.2).string(),
      },
    },
  };

  const socializatorIconState = {
    icon: Share,
    background: 'transparent',
    boxShadow: 'none',
    iconSize: 40,
  };

  const socializator = {
    buttonHeight: 30,
    iconSize: 18,
    iconsGap: 30,
    opened: {
      ...socializatorIconState,
    },
    closed: {
      ...socializatorIconState,
    },
  };

  const topicHeaderTexts = {
    title: {
      font: `400 32px/38px ${fonts.display}`,
    },
    subtitle: {
      font: `300 28px/34px ${fonts.text}`,
      color: atoms.colors.gray40,
    },
    lead: {
      ...texts.body,
    },
    divider: {
      background: color(atoms.colors.layout),
      margin: '10px 0',
    },
  };

  const topicFooter = {
    links: {
      showComma: false,
      borderBottom: `1px ${atoms.colors.gray40} solid`,
      font: `12px/15px ${fonts.text}`,
      color: atoms.colors.gray40,
      title: {
        font: `12px/15px ${fonts.text}`,
        color: atoms.colors.gray40,
      },
    },

    block: {
      spacing: 20,
      interitemSpacing: 5,
    },
  };


  const topicTags = {
    font: `11px ${fonts.text}`,
    color: '#AAA',
    showHash: false,
  };

  const wikiButtonStyles = {
    hover: {
      color: atoms.colors.magenta60,
      background: 'none',
      border: 'none',
    },

    disabled: {
      color: atoms.colors.gray70,
      background: 'none',
      border: 'none',
    },
  };

  const button = {
    const: {
      sizes: {
        small: {
          font: `12px/14px ${fonts.text}`,
          width: '87px',
          height: '26px',
          padding: '5px 9px',
        },
        medium: {
          font: `400 14px/18px ${fonts.text}`,
          width: '70px',
          height: '31px',
          padding: '7px 12px',
        },
        large: {
          font: `400 13px/16px ${fonts.text}`,
          width: '100px',
          height: '34px',
          padding: '8px 14px',
        },
      },
      radius: '60px',
    },

    primary: {
      idle: {
        color: atoms.colors.content,
        background: atoms.colors.blue60,
        border: `1px solid ${atoms.colors.blue60}`,
      },

      hover: {
        color: atoms.colors.content,
        background: atoms.colors.magenta60,
        border: `1px solid ${atoms.colors.magenta60}`,
      },

      active: {
        color: atoms.colors.content,
        background: atoms.colors.magenta60,
      },

      disabled: {
        color: atoms.colors.content,
        background: atoms.colors.gray80,
        border: `1px solid ${atoms.colors.gray80}`,
      },
    },

    magenta: {
      idle: {
        color: atoms.colors.content,
        background: atoms.colors.magenta60,
        border: `1px solid ${atoms.colors.magenta60}`,
      },

      hover: {
        color: atoms.colors.content,
        background: atoms.colors.blue60,
        border: `1px solid ${atoms.colors.blue60}`,
      },

      active: {
        color: atoms.colors.content,
        background: atoms.colors.magenta60,
      },

      disabled: {
        color: atoms.colors.content,
        background: atoms.colors.gray80,
        border: `1px solid ${atoms.colors.gray80}`,
      },
    },

    secondary: {
      idle: {
        color: atoms.colors.blue60,
        background: 'none',
        border: `1px solid ${atoms.colors.blue60}`,
      },

      hover: {
        color: atoms.colors.magenta60,
        background: 'none',
        border: `1px solid ${atoms.colors.magenta60}`,
      },

      active: {
        color: atoms.colors.magenta60,
        background: 'none',
        border: `1px solid ${atoms.colors.magenta60}`,
      },

      disabled: {
        color: atoms.colors.gray80,
        background: 'none',
        border: `1px solid ${atoms.colors.gray80}`,
      },
    },

    secondaryBlue: {
      idle: {
        color: atoms.colors.content,
        background: atoms.colors.azure70,
        border: `1px solid ${atoms.colors.azure70}`,
      },

      hover: {
        color: atoms.colors.content,
        background: atoms.colors.magenta60,
        border: `1px solid ${atoms.colors.magenta60}`,
      },

      active: {
        color: atoms.colors.content,
        background: atoms.colors.magenta60,
        border: `1px solid ${atoms.colors.magenta60}`,
      },

      disabled: {
        color: atoms.colors.content,
        background: atoms.colors.azure70,
        border: `1px solid ${atoms.colors.azure70}`,
      },
    },

    secondaryGrey: {
      idle: {
        color: atoms.colors.gray50,
        background: atoms.colors.divider,
        border: 'none',
      },

      hover: {
        color: atoms.colors.primary,
        background: atoms.colors.divider,
        border: 'none',
      },

      active: {
        color: atoms.colors.primary,
        background: atoms.colors.divider,
        border: 'none',
      },

      disabled: {
        color: atoms.colors.primary,
        background: atoms.colors.divider,
        border: 'none',
      },
    },

    secondaryViolet: {
      idle: {
        color: atoms.colors.primary,
        background: color(atoms.colors.violet).alpha(0.8).string(),
        border: 'none',
      },

      hover: {
        color: atoms.colors.primary,
        background: atoms.colors.violet,
        border: 'none',
      },

      active: {
        color: atoms.colors.primary,
        background: atoms.colors.violet,
        border: 'none',
      },

      disabled: {
        color: atoms.colors.primary,
        background: color(atoms.colors.violet).alpha(0.5).string(),
        border: 'none',
      },
    },

    secondaryWhite: {
      idle: {
        color: atoms.colors.primary,
        background: color(atoms.colors.content).alpha(0.8).string(),
        border: 'none',
      },

      hover: {
        color: atoms.colors.primary,
        background: atoms.colors.content,
        border: 'none',
      },

      active: {
        color: atoms.colors.primary,
        background: atoms.colors.content,
        border: 'none',
      },

      disabled: {
        color: atoms.colors.primary,
        background: color(atoms.colors.content).alpha(0.5).string(),
        border: 'none',
      },
    },

    secondary2: {
      idle: {
        color: atoms.colors.blue60,
        background: atoms.colors.layout,
        border: `1px solid ${atoms.colors.blue60}`,
      },

      hover: {
        color: atoms.colors.blue60,
        background: atoms.colors.layout,
        border: `1px solid ${atoms.colors.blue60}`,
      },

      active: {
        color: atoms.colors.magenta60,
        background: atoms.colors.layout,
        border: `1px solid ${atoms.colors.blue60}`,
      },

      disabled: {
        color: atoms.colors.gray80,
        background: atoms.colors.layout,
        border: `1px solid ${atoms.colors.gray80}`,
      },
    },

    tertiary: {
      idle: {
        color: atoms.colors.content,
        background: atoms.colors.primary,
        border: `1px solid ${atoms.colors.primary}`,
      },

      hover: {
        color: atoms.colors.content,
        background: atoms.colors.magenta60,
        border: `1px solid ${atoms.colors.magenta60}`,
      },

      active: {
        color: atoms.colors.content,
        background: atoms.colors.magenta60,
        border: `1px solid ${atoms.colors.magenta60}`,
      },

      disabled: {
        color: atoms.colors.content,
        background: atoms.colors.primary,
        border: `1px solid ${atoms.colors.primary}`,
      },
    },

    wiki1: {
      ...wikiButtonStyles,
      idle: {
        color: atoms.colors.primary,
        background: 'none',
        border: 'none',
      },
      active: {
        color: atoms.colors.content,
        background: atoms.colors.magenta60,
        border: `1px solid ${atoms.colors.magenta60}`,
      },

    },

    wiki2: {
      idle: {
        color: atoms.colors.gray40,
        background: 'none',
        border: 'none',
      },

      hover: {
        color: atoms.colors.gray40,
        background: 'none',
        border: 'none',
      },

      active: {
        color: atoms.colors.gray40,
        background: 'none',
        border: 'none',
      },

      disabled: {
        color: atoms.colors.gray70,
        background: 'none',
        border: 'none',
      },
    },

    wiki3: {
      ...wikiButtonStyles,
      idle: {
        color: atoms.colors.blue60,
        background: 'none',
        border: 'none',
      },
      active: {
        color: atoms.colors.content,
        background: atoms.colors.blue60,
        border: `1px solid ${atoms.colors.blue60}`,
      },

    },

    herbal: {
      idle: {
        color: atoms.colors.content,
        background: atoms.colors.herbal40,
        border: `1px solid ${atoms.colors.herbal40}`,
      },

      hover: {
        color: atoms.colors.content,
        background: atoms.colors.herbal30,
        border: `1px solid ${atoms.colors.herbal30}`,
      },

      active: {
        color: atoms.colors.content,
        background: atoms.colors.herbal30,
        border: `1px solid ${atoms.colors.herbal30}`,
      },

      disabled: {
        color: color(atoms.colors.content).alpha(0.8).string(),
        background: atoms.colors.herbal30,
        border: `1px solid ${atoms.colors.herbal30}`,
      },
    },
  };

  const input = {
    const: {
      radius: '2px',
    },

    basic: {
      idle: {
        background: atoms.colors.content,
        border: `1px solid ${atoms.colors.divider}`,
      },
      hover: {
        background: atoms.colors.content,
        border: `1px solid ${atoms.colors.gray60}`,
      },
      focused: {
        background: atoms.colors.content,
        border: `1px solid ${atoms.colors.gray60}`,
      },
      disabled: {
        border: `1px solid ${atoms.colors.divider}`,
      },
    },
  };

  const checkbox = {
    basic: {
      idle: {
        fill: atoms.colors.layout2,
      },
      focus: {
        fill: atoms.colors.azure60,
      },
      checked: {
        fill: atoms.colors.active1,
      },
      disabled: {
        fill: atoms.colors.divider,
      },
      checkedDisabled: {
        fill: atoms.colors.divider,
      },
    },
  };

  const searchInput = {
    placeholder: 'поиск...',
  };

  const paginatron = {
    separationText: '/',
    round: false,
    withOuterButtons: false,
    btnType: 'secondary',
    withoutBorder: true,
    NextPage: () => 'Вперёд',
    PrevPage: () => 'Назад',
    text: {
      font: `14px/17px ${fonts.text}`,
      color: atoms.colors.primary,
    },
    radius: button.const.radius,
  };

  const bullet = {
    const: {
      size: '5px',
    },

    basic: {
      idle: {
        background: (
          color(atoms.colors.quotes)
            .alpha(0.5)
            .string()
        ),
      },

      active: {
        background: atoms.colors.quotes,
        boxShadow: `0 0 0 3px ${atoms.colors.content}, 0 0 0 5px ${atoms.colors.quotes}`,
      },
    },
  };

  const bullets = {
    const: {
      bulletMargin: '0 7px 0 8px',
    },
  };

  const gallery = {
    slide: {
      borderRadius: 16,
    },

    captions: {
      padding: '15px 20px 0',
    },

    thumb: {
      borderRadius: 3,
    },

    info: {
      padding: '0 0 50px 0',
    },

    arrows: {
      idle: {
        fill: '#FFFFFF',
        stroke: '#000000',
      },
      idleFullscreen: {
        fill: '#000000',
        stroke: '#FE3A8A',
      },
      hover: {
        fill: '#FE3A8A',
        stroke: '#000000',
      },
      hoverFullscreen: {
        fill: '#FE3A8A',
        stroke: '#000000',
      },
    },
  };

  const drum = {
    const: {
      bulletsMargin: '24px 0 0 0',
    },
    arrow: {
      height: 50,
      width: 50,
      idle: {
        color: '#fff',
      },
    },
  };

  const captionCredits = {
    textAlign: 'left',
    margin: '15px 20px 0 0',
    padding: 0,
    caption: {
      font: `16px/20px ${fonts.text}`,
      color: atoms.colors.caption,
    },
    credits: {
      margin: '5px 0 0 0',
      font: `11px/14px ${fonts.text}`,
      color: atoms.colors.credits,
    },
  };

  const wikiContent = {
    section: {
      padding: 30,
    },
    navigation: {
      margin: 80,
    },
  };

  const counter = {
    font: `400 16px/20px ${fonts.text}`,
    mobile: {
      font: `400 12px/15px ${fonts.text}`,
    },
  };

  const errorPageParams = {
    background: atoms.colors.content,
    imgUrl: '/images/jsError.png',
    buttonSize: 'medium',
    buttonType: 'primary',
    buttonText: 'На главную страницу',
    imgWidth: 130,
    title: {
      font: `bold 22px/26px ${fonts.display}`,
    },
    subtitle: {
      font: `16px/20px ${fonts.text}`,
    },
    card: CardVertical1S,
    popular: {
      title: {
        font: `bold 22px/26px ${fonts.display}`,
      },
    },
  };

  const pages = {
    error: errorPageParams,
    notFound: {
      ...errorPageParams,
      title: {
        ...errorPageParams.title,
        name: 'Страница не найдена',
      },
      subtitle: {
        ...errorPageParams.subtitle,
        name: 'К сожалению, этой страницы не существует',
      },
    },
  };

  const topicContent = {
    widgetsWithMaxWidth: null,
    widgetsWithNegativeMobileIndent: {
      photoGallery: false,
      readMore: false,
      spoiler: true,
      publicationLink: true,
    },
    tableOfContents: {
      link: {
        borderBottom: `1px solid ${atoms.colors.primary}`,
      },
    },
  };

  const gameCompare = {
    progressbar: {
      separationText: '/',
      strokeColor: atoms.colors.layout,
      progressColor: atoms.colors.magenta60,
      vs: VS,
      progressInside: true,
      font: `400 12px/15px ${fonts.text}`,
    },
    announce: {
      font: `400 18px/27px ${fonts.text}`,
    },
    compare: {
      borderRadius: 10,
      margin: '0 -57px',
      progressType: 3,
      caption: {
        color: atoms.colors.layout,
        background: atoms.colors.primary,
        font: `700 12px/14px ${fonts.text}`,
      },
      credits: {
        color: atoms.colors.layout2,
        font: `400 11px/14px ${fonts.text}`,
      },
      draw: {
        backgroundColor: '#eeeef7',
        backgroundText: 'linear-gradient(45deg, #5ab0ff, #679de8)',
      },
      text: {
        background: '#eeeef7',
      },
    },
    tooltip: {
      buttonType: 'secondary2',
      scheme: {
        innerColor: 'transparent',
        outerColor: '#FE3A8A',
        titleFont: `400 16px/19px ${fonts.display}`,
        textFont: `400 16px/23px ${fonts.text}`,
      },
    },
    share: {
      color: atoms.colors.caption,
      text: 'Поделиться:',
      font: `400 12px/15px ${fonts.text}`,
      showBorder: true,
      marginTop: '50px',
    },
    leaderBoard: {
      card: CompareCard,
      table: {
        borderColor: atoms.colors.gray80,
        background: atoms.colors.layout,
        activeBackground: '#eeeef7',
        indent: NegativeMobile,
      },
      header: {
        color: atoms.colors.caption,
        font: `400 12px/14px ${fonts.text}`,
        questionBackground: '#f3f3f3',
        questionFill: atoms.colors.hint,
      },
      count: {
        background: '#f2f2f2',
        font: `700 15px/18px ${fonts.text}`,
        activeBackground: atoms.colors.layout,
        activeColor: atoms.colors.primary,
      },
      progressbar: {
        height: '1px',
        progressHeight: '3px',
        background: '#b3b3b3',
        defaultProgress: atoms.colors.magenta60,
        finalProgress: atoms.colors.magenta60,
        activeBackground: '#a7a7ad',
        font: `300 12px/17px ${fonts.text}`,
      },
      caption: `400 16px/20px ${fonts.text}`,
      ratings: `300 12px/17px ${fonts.display}`,
    },
    concidences: {
      font: `400 18px/27px ${fonts.text}`,
      margin: '0 0 15px',
      highlight: false,
    },
    winner: {
      icon: Winner,
      background: 'linear-gradient(180deg, #764E9A 0%, #2C337D 100%), #8253AA',
      borderRadius: '4px',
      imageTop: '30px',
      strokeColor: atoms.colors.layout,
      padding: '30px',
      viewBox: '0 0 870 610',
      iconStep: 120,
      fillColor: atoms.colors.content,
    },
  };

  const interview = {
    question: {
      name: {
        color: atoms.colors.active1,
      },
    },
  };

  const smartTeaser = {
    gameCompare: {
      readMore: {
        color: atoms.colors.content,
      },
    },
  };

  return deepmerge({
    fonts,
    icons,
    texts,
    layout,
    animations,
    controls: {
      shapka,
      scooter,
      captionCredits,
      gallery,
      drum,
      vikontSideBySide,
      vikontSpoiler,
      vikontReadMore,
      vikontIncut,
      link,
      topicTopline,
      socializator,
      topicHeaderTexts,
      topicFooter,
      topicContent,
      wikiContent,
      button,
      input,
      checkbox,
      bullet,
      bullets,
      topicTags,
      counter,
      searchInput,
      paginatron,
      gameCompare,
      interview,
      smartTeaser,
    },
    pages,
  }, atoms);
}
