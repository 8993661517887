import PropTypes from 'prop-types';

import SelectPointer from 'site/icons/SelectPointer.svg';

import Select from 'site/components/Select';

import { symbolsMap } from 'site/constants';

import styles from './index.styl';

const defaultValue = {
  label: 'Все буквы',
  value: 'all',
};

export default function MobileAlphabet(props) {
  const {
    symbols,
    char,
    toggleFilter,
  } = props;

  const symbolKeys = Object.keys(symbols);
  const valueIsValid = symbolKeys.find((el) => symbols[el].includes(char));
  const groupedOptions = symbolKeys.map((key) => ({
    label: symbolsMap[key].selectLabel,
    options: symbols[key].map((el) => ({
      label: el,
      value: el,
    })),
  }));

  const options = [defaultValue].concat(groupedOptions);

  return (
    <Select
      name='symbols'
      className={styles.symbolSelect}
      options={options}
      value={valueIsValid ? ({
        label: char,
        value: char,
      }) : defaultValue}
      onChange={(el) => toggleFilter('first_char', el.value)}
      isDisabled={options.length < 2}
      isSearchable={false}
      icon={SelectPointer}
    />
  );
}

MobileAlphabet.propTypes = {
  symbols: PropTypes.shape({
    ru: PropTypes.array,
    en: PropTypes.array,
    other: PropTypes.array,
  }).isRequired,
  char: PropTypes.string,
  toggleFilter: PropTypes.func,
};
